/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Row, Switch, Card, Input, Form, Button, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Wrapper from '../comnon/wrapper';
import useTitle from '../comnon/title';
import { useTranslation } from "react-i18next";
import { EditFilled } from '@ant-design/icons';
import UploadFilesandLogo from '../comnon/uploadFilesandLogo';
const Settings = () => {

    const { t, i18n } = useTranslation();
    useTitle(t("settings.view_title"));
    const dispatch = useDispatch();
    const { isSettingToggleLoading, settingToggleStatus, settingsData, isSettingLoading, isSettingUpdateStatus, isSettingUpdateLoading } = useSelector(state => state.user);

    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [logo, setLogo] = useState({});
    const [supportEn, setSupportEn] = useState({});
    const [supportFr, setSupportFr] = useState({});
    const [existsSettingData, setSettingsData] = useState({});
    const [form] = Form.useForm();

    useEffect(() => {
        loadSettings();
    }, []);

    const loadSettings = (params = {}) => {
        dispatch({ type: 'SETTINGS_REQUEST', formData: params });
    }

    const onSwitchChange = (e) => {
        dispatch({ type: "TOGGLE_SETTINGS_REQUEST", status: e })
    }

    useEffect(() => {
        if (!isSettingToggleLoading && Object.keys(settingToggleStatus).length > 0) {
            loadSettings();
        }
    }, [isSettingToggleLoading, settingToggleStatus]);

    useEffect(() => {
        if (!isSettingUpdateLoading && isSettingUpdateStatus === "success") {
            setLogo({});
            setSupportEn({});
            setSupportFr({});
            loadSettings();
        }
    }, [isSettingUpdateLoading, isSettingUpdateStatus]);

    useEffect(() => {
        if (!isSettingLoading && Object.keys(settingsData).length > 0) {
            setIsSwitchLoading(true);
            setChecked(settingsData.enable_stream_survey === 1 ? true : false);
            form.setFieldsValue({ default_email: settingsData.default_email !== null ? settingsData.default_email : "" })
            form.setFieldsValue({
                enable_stream_survey: settingsData.enable_stream_survey,
                filter_field_1: settingsData.filter_field_1,
                filter_field_2: settingsData.filter_field_2,
                client_name: settingsData.client_name
            });
            setSettingsData(settingsData);
            setTimeout(() => {
                setIsSwitchLoading(false);
            }, 200);
        }
    }, [settingsData, isSettingLoading]);

    const onFinish = (values) => {
        let formData = new FormData();

        for (const property in values) {
            formData.append(property, values[property])
        }

        if (Object.keys(logo).length > 0) {
            formData.append('logo', logo)
        }

        if (Object.keys(supportEn).length > 0) {
            formData.append('en_support_guide', supportEn)
        }

        if (Object.keys(supportFr).length > 0) {
            formData.append('fr_support_guide', supportFr)
        }

        dispatch({ type: "UPDATE_SETTINGS_REQUEST", formData: formData })
    }
    const onFinishFailed = () => { }
    return (
        <Wrapper title="survey_id View" >
            <div className='padding-around input-class'>
                {isSettingLoading && <Spin style={{ width: "100%" }} />}
                {!isSettingLoading &&
                    <>
                        <Form
                            layout='vertical'
                            className='sign-form'
                            name='basic'
                            form={form}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Row className='nav-sub-header'>
                                <Col xs={24} sm={24} md={16} lg={16} xl={16} className="red">
                                    <Card className='archive-card'>
                                        <div className='content'>
                                            <p>{t('settings.label')}</p>
                                            <div className='switch-delete'>
                                                <p>
                                                    {isSwitchLoading && <Switch
                                                        checkedChildren={<span>{t('settings.enable')}</span>}
                                                        unCheckedChildren={<span>{t('settings.disabled')}</span>}
                                                        defaultChecked={checked}
                                                        size='large'
                                                        loading={isSwitchLoading}
                                                    />}
                                                    {!isSwitchLoading && <p>
                                                        <Switch
                                                            checkedChildren={<span>{t('settings.enable')}</span>}
                                                            unCheckedChildren={<span>{t('settings.disabled')}</span>}
                                                            defaultChecked={checked}
                                                            onChange={(e) => onSwitchChange(e)}
                                                            size='large'
                                                            loading={isSwitchLoading || isSettingToggleLoading}
                                                        />
                                                    </p>}
                                                </p>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>

                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={16} lg={16} xl={16} className="red">
                                    <Form.Item
                                        style={{ display: 'none' }}
                                        name={'enable_stream_survey'}
                                        className='form-field'
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={t('settings.support_label')}
                                        name='default_email'
                                        className='form-field'
                                        rules={[

                                            {
                                                type: 'email',
                                                message: t('user.error_email_valid'),
                                            },
                                            {
                                                required: false,
                                                message: t('user.error_email'),
                                            }
                                        ]}
                                    >
                                        <Input style={{ width: "350px" }} size="large" placeholder={t('settings.support_label')} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="red">
                                    <Form.Item
                                        label={t('settings.client_name')}
                                        name='client_name'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                                message: '',
                                            }
                                        ]}
                                    >
                                        <Input style={{ width: "350px" }} size="large" placeholder={t('settings.client_name')} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="red">
                                    <Form.Item
                                        label={t('settings.filter_field1')}
                                        name='filter_field_1'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                                message: '',
                                            }
                                        ]}
                                    >
                                        <Input style={{ width: "350px" }} size="large" placeholder={t('settings.filter_field1')} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="red">
                                    <Form.Item
                                        label={t('settings.filter_field2')}
                                        name='filter_field_2'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                                message: '',
                                            }
                                        ]}
                                    >
                                        <Input style={{ width: "350px" }} size="large" placeholder={t('settings.filter_field2')} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {!isSettingLoading &&
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="red">
                                        <Form.Item
                                            label={t('settings.logo')}
                                            className='form-field'
                                        >
                                            <UploadFilesandLogo
                                                fileName={settingsData.logo_url}
                                                supportType="image"
                                                setFileObj={(e) => {
                                                    setLogo(e)
                                                }}
                                                label={t('settings.upload_logo')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8} className="red">
                                        <Form.Item
                                            label={t('settings.support_guide_en')}
                                            className='form-field'
                                        >
                                            <UploadFilesandLogo
                                                fileName={settingsData.en_support_guide_url}
                                                supportType="pdf"
                                                setFileObj={(e) => setSupportEn(e)}
                                                label={t('settings.upload_support_guide_en')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className="red">
                                        <Form.Item
                                            label={t('settings.support_guide_fr')}
                                            name='filter_field_2'
                                            className='form-field'
                                            rules={[
                                                {
                                                    required: false,
                                                    message: '',
                                                }
                                            ]}
                                        >
                                            <UploadFilesandLogo
                                                fileName={settingsData.fr_support_guide_url}
                                                setFileObj={(e) => setSupportFr(e)}
                                                supportType="pdf"
                                                label={t('settings.upload_support_guide_fr')}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    shape="round"
                                    loading={isSettingUpdateLoading}
                                    disabled={isSettingUpdateLoading}
                                    size={"large"}>
                                    {t('common.update')}
                                </Button>
                            </Row>
                        </Form>
                    </>
                }
            </div>
        </Wrapper>
    )
};

export default Settings;                    
