const initialState = {
    isLoading: false,
    organizationList: [],

    isAddLoading: false,
    isAddedMsg: "",

    isGetLoading: false,
    organizationData: {},

    isDeleteLoading: false,
    deleteStatus: "",

    isToggleLoading: false,
    orgStatus: "",

    error: ""

};

const organizationReducer = (state = initialState, action = {}) => {

    switch (action.type) {
        case "ORGANIZATION_LIST_REQUEST":
            state = {
                ...state,
                isLoading: true,
                organizationList: []

            };
            break;

        case "ORGANIZATION_LIST_SUCCESS":
            state = {
                ...state,
                isLoading: false,
                organizationList: action.data
            };
            break;

        case "ORGANIZATION_LIST_ERROR":
            state = {
                ...state,
                isLoading: false,
                organizationList: [],
                error: action.error,
            };
            break;

        /******************************/
        case "ORGANIZATION_ADD_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                isAddedMsg: ''

            };
            break;

        case "ORGANIZATION_ADD_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                isAddedMsg: "success"
            };
            break;

        case "ORGANIZATION_ADD_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                isAddedMsg: ""
            };
            break;

        /******************************/
        case "ORGANIZATION_UPDATE_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                isAddedMsg: ''

            };
            break;

        case "ORGANIZATION_UPDATE_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                isAddedMsg: "success"
            };
            break;

        case "ORGANIZATION_UPDATE_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                isAddedMsg: ""
            };
            break;

        /******************************/
        case "ORGANIZATION_DETAIL_REQUEST":
            state = {
                ...state,
                isGetLoading: true,
                organizationData: {}

            };
            break;

        case "ORGANIZATION_DETAIL_SUCCESS":
            state = {
                ...state,
                isGetLoading: false,
                organizationData: action.data
            };
            break;

        case "ORGANIZATION_DETAIL_ERROR":
            state = {
                ...state,
                isGetLoading: false,
                organizationData: {},
                error: action.error,
            };
            break;

        /******************************/
        case "ORGANIZATION_DELETE_REQUEST":
            state = {
                ...state,
                isDeleteLoading: true,
                deleteStatus: ""

            };
            break;

        case "ORGANIZATION_DELETE_SUCCESS":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteStatus: "success"
            };
            break;

        case "ORGANIZATION_DELETE_ERROR":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteStatus: ""
            };
            break;

        /******************************/
        case "ORGANIZATION_TOGGLE_REQUEST":
            state = {
                ...state,
                isToggleLoading: true,
                orgStatus: ""

            };
            break;

        case "ORGANIZATION_TOGGLE_SUCCESS":
            state = {
                ...state,
                isToggleLoading: false,
                orgStatus: action.data
            };
            break;

        case "ORGANIZATION_TOGGLE_ERROR":
            state = {
                ...state,
                isToggleLoading: false,
                orgStatus: ""
            };
            break;


        case "CLEAR_STATUS":
            state = {
                ...state,
                isAddedMsg: "",
                deleteStatus: "",
                organizationData: {},
                orgStatus: ""
            };
            break;

        default:

    }
    return state;
};

export default organizationReducer;