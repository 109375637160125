/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Input } from 'antd';
import { PlusOutlined, EditFilled, DeleteFilled, } from '@ant-design/icons';
import { Space, Table, Tag, message, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Wrapper from '../comnon/wrapper';
import DeleteModal from '../comnon/modal.js';
import useTitle from '../comnon/title';
import { PER_PAGE } from '../../../utils/constant';
import { useTranslation } from "react-i18next";
const { Search } = Input;
const { Column } = Table;


const Organization = () => {

    const { t } = useTranslation();
    useTitle(t("organization.view_title"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, organizationList, isDeleteLoading, deleteStatus } = useSelector(state => state.organization);
    const [data, setData] = useState([]);

    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    useEffect(() => {
        loadOrganization();
    }, []);

    const loadOrganization = (params = {}) => {
        dispatch({ type: 'ORGANIZATION_LIST_REQUEST', formData: params });
    }

    useEffect(() => {
        if (!isLoading && organizationList.length > 0) {
            let tmpOrgList = [];
            organizationList.map((item) => {
                let Obj = {};
                Obj.name = item.name;
                Obj.key = item.id;
                Obj.status = item.archived_at === null ? "Active" : "Archived";
                Obj.other = { ...item.client_id };
                Obj.streams = item.streams;
                tmpOrgList.push(Obj);
                return true;
            });
            setData(tmpOrgList);
        } else if (!isLoading && organizationList.length === 0) {
            setData([]);
        }
    }, [isLoading, organizationList])

    const onSearch = (e) => {
        loadOrganization({ q: e });
    }

    const onDelete = (e) => {
        dispatch({ type: 'ORGANIZATION_DELETE_REQUEST', id: deleteId });
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteStatus === "success") {
            setVisible(false);
            dispatch({ type: 'CLEAR_STATUS' });
            message.success({ content: t('organization.delete_success_msg'), className: 'custom-success-class' });
            loadOrganization();
        }
    }, [isDeleteLoading, deleteStatus]);

    return (
        <Wrapper title="Organization View" >
            <div className='padding-around'>
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} className="red">
                        <div className='nav-add-btn'>
                            <Button id="add-org" type="primary" shape="circle" onClick={() => navigate('/organization/add')} icon={<PlusOutlined />} size={"medium"} />
                            <label htmlFor="add-org" className="nav-add-label">{t("organization.add")}{ }</label>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="green">
                        <Search allowClear placeholder={t("common.search_text")} onSearch={onSearch} enterButton />
                    </Col>
                </Row>

                <Row className='listing-table mt10'>
                    <Table
                        style={{ width: "100%" }}
                        // columns={columns}
                        dataSource={data}
                        loading={isLoading}
                        pagination={{
                            pageSize: PER_PAGE,
                            showTotal: (total, range) => `${range[0]}-${range[1]} ${t("common.of")} ${total} ${t("common.records")}`,
                            hideOnSinglePage: true,
                        }}
                    >
                        <Column
                            title={t("common.name")}
                            dataIndex='name'
                            key='name'
                            sorter={(a, b) => a.name.localeCompare(b.name)}
                            render={(text) => <b>{text}</b>}
                        />


                        {/* <Column
                            title={t("organization.stream")}
                            dataIndex='streams'
                            key='streams'
                            render={(_, record) => {
                                if (record.streams !== undefined && record.streams !== null && record.streams.length > 0) {
                                    return record.streams.map((item, index) => {
                                        return (
                                            <Tag key={index}>
                                                {item.name_en}
                                            </Tag>
                                        )
                                    });
                                }
                            }}
                        /> */}

                        <Column
                            title={t("common.status")}
                            key='status'
                            dataIndex='status'
                            filters={[
                                {
                                    text: 'Active',
                                    value: 'Active',
                                },
                                {
                                    text: 'Archived',
                                    value: 'Archived',
                                }
                            ]}
                            filterMode='tree'
                            onFilter={(value, record) => record.status.startsWith(value)}
                            render={(_, { status }) => {
                                let color = (status !== 'Active') ? "default" : "success";
                                return (
                                    <Tag color={color} key={status}>
                                        {status.toUpperCase()}
                                    </Tag>)
                            }}
                        />
                        <Column
                            title={t("common.action")}
                            key='action'
                            render={(_, record) => (
                                <Space size="middle">
                                    <Tooltip placement="top" title={t("organization.edit_tooltip")}>
                                        <EditFilled className='cursor' onClick={() => navigate(`/organization/edit/${record.key}`)} style={{ fontSize: "18px" }} />
                                    </Tooltip>
                                    <Tooltip placement="top" color={"red"} title={t("organization.delete_tooltip")}>
                                        <DeleteFilled
                                            onClick={() => {
                                                setDeleteId(record.key);
                                                setVisible(true)
                                            }}
                                            className='cursor' style={{ fontSize: "18px" }} />
                                    </Tooltip>
                                </Space>
                            )}
                        />
                    </Table>
                    <DeleteModal
                        visible={visible}
                        subTitle={t("organization.delete_confirm")}
                        setVisible={() => { setDeleteId(""); setVisible(false) }}
                        onDelete={() => onDelete()}
                        isDeleteLoading={isDeleteLoading}
                    />
                </Row>
            </div>
        </Wrapper>
    )
};

export default Organization;                    