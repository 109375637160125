const initialState = {
    isClientsLoading: false,
    clientsList: [],

    isInviteSending: false,
    inviteMsg: "",

    isRequestSending: false,
    statusMsg: "",

    isForgetPasswordLoading: false,
    forgetMsg: "",

    isResendLoading: false,
    resendMsg: "",

    isUpdateExpiryLoading: false,
    updateExpiryMsg: "",

    isUnlinkLoading: false,
    unlinkMsg: "",

    isLoginLoading: false,
    loginMsg: "",

    isMFARequestSending: false,
    mfaMsg: "",

    loginData: {},

    isMFARequestResendLoading: false,
    mfaResendMsg: "",

    isUpdateLoading: false,
    updateMsg: "",

    error: "",
    isSessionExpiryRequestSending: false,


};

const reducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case "CLIENTS_LIST_REQUEST":
            state = {
                ...state,
                isClientsLoading: true,
                clientsList: []

            };
            break;

        case "CLIENTS_LIST_SUCCESS":
            state = {
                ...state,
                isClientsLoading: false,
                clientsList: action.data.data

            };
            break;

        case "CLIENTS_LIST_ERROR":
            state = {
                ...state,
                isClientsLoading: false,
                error: action.error
            };
            break;

        /*------------ */
        case "CLIENT_INVITE_REQUEST":
            state = {
                ...state,
                isInviteSending: true,
                inviteMsg: ""

            };
            break;

        case "CLIENT_INVITE_SUCCESS":
            state = {
                ...state,
                isInviteSending: false,
                inviteMsg: action.msg

            };
            break;

        case "CLIENT_INVITE_ERROR":
            state = {
                ...state,
                isInviteSending: false,
                inviteMsg: action.error
            };
            break;

        /*------------ */
        case "SET_PASSWORD_REQUEST":
            state = {
                ...state,
                isRequestSending: true,
                statusMsg: ""

            };
            break;

        case "SET_PASSWORD_SUCCESS":
            state = {
                ...state,
                isRequestSending: false,
                statusMsg: "success"

            };
            break;

        case "SET_PASSWORD_ERROR":
            state = {
                ...state,
                isRequestSending: false,
                statusMsg: action.error
            };
            break;

        /*------------ */
        case "FORGET_PASSWORD_REQUEST":
            state = {
                ...state,
                isForgetPasswordLoading: true,
                forgetMsg: ""
            };
            break;

        case "FORGET_PASSWORD_SUCCESS":
            state = {
                ...state,
                isForgetPasswordLoading: false,
                forgetMsg: "success"
            };
            break;

        case "FORGET_PASSWORD_ERROR":
            state = {
                ...state,
                isForgetPasswordLoading: false,
                forgetMsg: action.error
            };
            break;

        /*------------ */
        case "RESEND_CODE_REQUEST":
            state = {
                ...state,
                isResendLoading: true,
                resendMsg: ""
            };
            break;

        case "RESEND_CODE_SUCCESS":
            state = {
                ...state,
                isResendLoading: false,
                resendMsg: "success"
            };
            break;

        case "RESEND_CODE_ERROR":
            state = {
                ...state,
                isResendLoading: false,
                resendMsg: action.error
            };
            break;

        /*------------ */
        case "UPDATE_EXPIRY_REQUEST":
            state = {
                ...state,
                isUpdateExpiryLoading: true,
                updateExpiryMsg: ""
            };
            break;

        case "UPDATE_EXPIRY_SUCCESS":
            state = {
                ...state,
                isUpdateExpiryLoading: false,
                updateExpiryMsg: "success"
            };
            break;

        case "UPDATE_EXPIRY_ERROR":
            state = {
                ...state,
                isUpdateExpiryLoading: false,
                updateExpiryMsg: action.error
            };
            break;
        /*------------ */
        case "MFA_REQUEST":
            state = {
                ...state,
                isMFARequestSending: true,
                mfaMsg: ""
            };
            break;

        case "MFA_SUCCESS":
            state = {
                ...state,
                isMFARequestSending: false,
                mfaMsg: "success",
                loginData: action.data
            };
            break;

        case "MFA_ERROR":
            state = {
                ...state,
                isMFARequestSending: false,
                mfaMsg: action.error
            };
            break;

        case "MFA_REQUEST_RESEND":
            state = {
                ...state,
                isMFARequestResendLoading: true,
                mfaResendMsg: ""
            };
            break;

        case "MFA_REQUEST_RESEND_SUCCESS":
            state = {
                ...state,
                isMFARequestResendLoading: false,
                mfaResendMsg: "success",
            };
            break;

        case "MFA_REQUEST_RESEND_ERROR":
            state = {
                ...state,
                isMFARequestResendLoading: false,
                mfaResendMsg: action.error
            };
            break;
            
        /*------------ */
        case "UNLINK_USER_REQUEST":
            state = {
                ...state,
                isUnlinkLoading: true,
                unlinkMsg: ""
            };
            break;

        case "UNLINK_USER_SUCCESS":
            state = {
                ...state,
                isUnlinkLoading: false,
                unlinkMsg: "success"
            };
            break;

        case "UNLINK_USER_ERROR":
            state = {
                ...state,
                isUnlinkLoading: false,
                unlinkMsg: action.error
            };
            break;

        /*------------ */
        case "LOGIN_REQUEST":
            state = {
                ...state,
                isLoginLoading: true,
                loginMsg: ""
            };
            break;

        case "LOGIN_SUCCESS":
            state = {
                ...state,
                isLoginLoading: false,
                loginMsg: "success",
                loginData: action.data
            };
            break;

        case "LOGIN_ERROR":
            state = {
                ...state,
                isLoginLoading: false,
                loginMsg: action.error
            };
            break;

        /*------------ */
        case "USER_DETAIL_UPDATE_REQUEST":
            state = {
                ...state,
                isUpdateLoading: true,
                updateMsg: ""
            };
            break;

        case "USER_DETAIL_UPDATE_SUCCESS":
            state = {
                ...state,
                isUpdateLoading: false,
                updateMsg: "success",
                loginData: action.data
            };
            break;

        case "USER_DETAIL_UPDATE_ERROR":
            state = {
                ...state,
                isUpdateLoading: false,
                updateMsg: action.error
            };
            break;

        case "CLEAR_INVITE":
            state = {
                ...state,
                isInviteSending: false,
                inviteMsg: "",
                statusMsg: "",
                forgetMsg: "",
                resendMsg: "",
                updateExpiryMsg: "",
                unlinkMsg: "",
                updateMsg: ""
            };
            break;

        case "CLEAR_LOGIN_DATA":
            state = {
                ...state,
                loginData: {},
                loginMsg: ""
            };
            break;
        case 'REFRESH_SESSION_REQUEST':
            state = {
                ...state,
                isSessionExpiryRequestSending: true
            };
            break;
        case 'REFRESH_SESSION_SUCCESS':
            state = {
                ...state,
                isSessionExpiryRequestSending: false
            };
            break;
        case 'REFRESH_SESSION_ERROR':
            state = {
                ...state,
                isSessionExpiryRequestSending: false
            };
            break;

        default:

    }
    return state;
};

export default reducer;