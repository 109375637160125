import React from 'react'
import AuthWrapper from '../authwrapper';
import LoginComponent from './loginComponent';
import QueueAnim from 'rc-queue-anim';
import { useTranslation } from "react-i18next";

function Login() {
    const { t } = useTranslation();
    return (
        <QueueAnim
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
            >
            <AuthWrapper>
            <div className='login-page-title'>{t("login.login_title")}</div>
                <LoginComponent />
            </AuthWrapper>
        </QueueAnim>

    )
}
export default Login;
