import { all } from 'redux-saga/effects'


import AuthSaga from './auth';
import OrganizationSaga from './organization';
import UsersSaga from './user';
import ReportSaga from './reports';
import StreamSaga from './stream';
import SurveySaga from './survey';

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
function* rootSaga() {
    yield all([
        AuthSaga(),
        OrganizationSaga(),
        UsersSaga(),
        ReportSaga(),
        StreamSaga(),
        SurveySaga()
    ])
}

export default rootSaga;