import { put, takeLatest, call } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { deleteRequestAPI, getRequestAPI, postRequestAPI, putRequestAPI } from '../../services/API/api';

function* getOrganizationList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `organizations`, params: formData });

        if (response.status === 200) {
            yield put({ type: 'ORGANIZATION_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'ORGANIZATION_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_LIST_ERROR', error: error.message || "" });
    }
}

function* addOrganization({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `organization`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'ORGANIZATION_ADD_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'ORGANIZATION_ADD_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_ADD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* updateOrganization({ id, formData }) {
    try {
        const response = yield call(putRequestAPI, { url: `organization/${id}`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'ORGANIZATION_UPDATE_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'ORGANIZATION_UPDATE_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_UPDATE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* getOrganizationDetail({ id }) {
    try {
        const response = yield call(getRequestAPI, { url: `organization/${id}`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'ORGANIZATION_DETAIL_SUCCESS', data: response.data.data || [] });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });

            yield put({ type: 'ORGANIZATION_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_DETAIL_ERROR', error: error.message || "" });
    }
}
function* deleteOrganization({ id }) {
    try {
        const response = yield call(deleteRequestAPI, { url: `organization/${id}` });
        if (response.status === 200 || response.status === 204) {
            yield put({ type: 'ORGANIZATION_DELETE_SUCCESS' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'ORGANIZATION_DELETE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_DELETE_ERROR', error: error.message || "" });
    }
}
function* updateStatus({ orgId, status }) {
    try {
        const response = yield call(postRequestAPI, { url: `organization/${orgId}/toggle-status` });
        if (response.status === 200) {
            yield put({ type: 'ORGANIZATION_TOGGLE_SUCCESS', data: response.data.data.archived_at === null ? 'activated' : 'archived' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'ORGANIZATION_TOGGLE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ORGANIZATION_TOGGLE_ERROR', error: error.message || "" });
    }
}

function* watchDonorConfigSagasRequests() {

    yield takeLatest('ORGANIZATION_LIST_REQUEST', getOrganizationList);
    yield takeLatest('ORGANIZATION_ADD_REQUEST', addOrganization);
    yield takeLatest('ORGANIZATION_UPDATE_REQUEST', updateOrganization);
    yield takeLatest('ORGANIZATION_DETAIL_REQUEST', getOrganizationDetail);
    yield takeLatest('ORGANIZATION_DELETE_REQUEST', deleteOrganization);
    yield takeLatest('ORGANIZATION_TOGGLE_REQUEST', updateStatus);

}

function* donorConfigSagas() {
    yield watchDonorConfigSagasRequests();
}

export default donorConfigSagas;