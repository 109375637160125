import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import CryptoJS from 'crypto-js';
import {Spin} from 'antd';
import {useSearchParams} from 'react-router-dom';
import {ROLE_NAME, TABLEAU_URL, TABLEAU_APP_ID, TABLEAU_SECRET_ID, TABLEAU_SECRET_VALUE, TABLEAU_USER_EMAIL} from '../../../utils/constant';
import Wrapper from '../comnon/wrapper';
import useTitle from '../comnon/title';
import {getUserData, role} from '../../../utils/functions';


const ViewReport = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useTitle(t("report.view_title"));
    const { isReportViewLoading, isGetGroupLoading, groupReportData } = useSelector(state => state.report);
    const [searchParams] = useSearchParams();
    const [orgIds, setOrgIds] = useState("");
    const [workbook, setWorkbook] = useState('');
    const [dashboard, setDashboard] = useState('');
    const [filterOrg, setFilterOrg] = useState('');
    const [visibility, setVisibility] = useState(false);
    const [token, setToken] = useState('');

    function createToken(){
        const scp = ["tableau:views:embed"]
        var header = {
            "alg": "HS256",
            "typ": "JWT",
            "iss": TABLEAU_APP_ID, //CONNECTED APP ID
            "kid": TABLEAU_SECRET_ID, //CONNECTED APP SECRET ID
        };
        var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        var encodedHeader = base64url(stringifiedHeader);
        var claimSet = {
            "sub": TABLEAU_USER_EMAIL, // THE USER NAME, like aalteirac@tableau.com
            "aud":"tableau",
            "nbf":Math.round(new Date().getTime()/1000)-100,
            "jti":new Date().getTime().toString(),
            "iss": TABLEAU_APP_ID,
            "scp": [
                "tableau:views:embed" // THIS MUST BE AN ARRAY, even if single entry
            ],
            "exp": Math.round(new Date().getTime()/1000)+100
        };
        const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(claimSet));
        const encodedData = base64url(stringifiedData);
        const token = encodedHeader + "." + encodedData;
        let signature = CryptoJS.HmacSHA256(token, TABLEAU_SECRET_VALUE); //CONNECTED APP SECRET
        signature = base64url(signature);
        return token + "." + signature;
    }
    function base64url(source) {
        let encodedSource = CryptoJS.enc.Base64.stringify(source);
        encodedSource = encodedSource.replace(/=+$/, '');
        encodedSource = encodedSource.replace(/\+/g, '-');
        encodedSource = encodedSource.replace(/\//g, '_');
        return encodedSource;
    }

    useEffect(() => {
        const token = createToken();
        setToken(token);
    }, [])

    useEffect(() => {
        if (searchParams.get('group_id') !== null) {
            dispatch({ type: 'REPORT_GROUP_DETAIL_REQUEST', id: searchParams.get('group_id') });
        }
    }, [searchParams]);

    useEffect(() => {
        if (!isGetGroupLoading && Object.keys(groupReportData).length > 0) {
            if (searchParams.get('report_id') !== null) {
                let repId = searchParams.get('report_id');
                if (groupReportData.reports.length > 0) {
                    let currentReport = {};
                    groupReportData.reports.map((repData) => {
                        if (repData.id === parseInt(repId)) {
                            currentReport = repData;
                        }
                    });
                    if ([ROLE_NAME.ORG_ADMIN, ROLE_NAME.ORG_USER].includes(role())) {
                        setFilterOrg(getUserData().organization.organization_identifier)
                    }
                    if (Object.keys(currentReport).length > 0) {
                        setDashboard(currentReport.dashboard)
                        setWorkbook(currentReport.workbook)
                    }
                }
            }
        }
    }, [isGetGroupLoading, groupReportData]);


    useEffect(() => {
        let intervalId = ""
        let viz2 = document.getElementById('tableauViz');
        if(viz2 !== null){
            //viz2.toolbar = 'hidden';
        }
        if (!isGetGroupLoading && filterOrg !== "") {
            setVisibility(true);
            intervalId = setInterval(() => {
                let viz = document.getElementById('tableauViz');
                if (viz.workbook !== null && viz.workbook._workbookImpl !== undefined && viz.workbook.activeSheet !== undefined) {
                    let sheet = viz.workbook.activeSheet;
                    const saleMap = sheet.worksheets.find(ws => ws?.name.includes("_OrgID"));
                    if (saleMap !== undefined && saleMap !== null) {
                        saleMap.applyFilterAsync("Org ID", [filterOrg], 'replace');
                        setTimeout(() => {
                            clearInterval(intervalId);
                            setTimeout(() => {
                                setVisibility(false);
                            }, 1000);
                        }, 1000);
                    } else {
                        clearInterval(intervalId);
                        setVisibility(false);
                    }
                }
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [filterOrg, isGetGroupLoading]);

    return (
        <Wrapper>
            <div className='padding-around report-mngmnt-view'>
                {((isGetGroupLoading && isReportViewLoading) || visibility) && <Spin style={{ width: "100%" }} />}
                {(!isGetGroupLoading && !isReportViewLoading) &&
                    <>
                        <div style={{ visibility: !visibility ? 'visible' : 'hidden' }}>
                            <tableau-viz
                                id="tableauViz"
                                src={`${TABLEAU_URL}${workbook}/${dashboard}`}
                                token={token}
                                toolbar="bottom"
                                hide-tabs
                                hide-close-button
                                hide-edit-button
                                hide-edit-in-desktop-button
                            >
                            </tableau-viz>
                        </div>
                    </>

                }
            </div>
        </Wrapper>

    )
};

export default ViewReport;