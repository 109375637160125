
exports.urlParam = (name) => {
    // eslint-disable-next-line
    let results = new RegExp('[\?&]' + name + '=([^&#]*)', 'i').exec(window.location.href);
    if (results === null) {
        return null;
    } else {
        return results[1] || 0;
    }
};

exports.showAvtarName = () => {
    let name = this.getFullName();
    if (name !== "") {
        let fullName = name.split(' ');
        let initials = "";
        if (fullName.length > 0) {
            if (fullName[0] !== undefined && fullName[0] !== "") {
                initials = fullName[0].charAt(0);
            }
            if (fullName[1] !== undefined && fullName[1] !== "") {
                initials = initials + fullName[1].charAt(0)
            }
        }
        return initials !== "" ? initials.toUpperCase() : "";
    }
    return "";
}

exports.logout = () => {
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('jwt');
    localStorage.removeItem('remember');
}

exports.isLogin = () => {
    const jwtToken = localStorage.getItem('jwt');
    if (jwtToken) {
        if (jwtToken != 'undefined') {
            return true;
        }
    }
    return false;
}

exports.isAuthorized = (allowedList = []) => {

    const loggedInUser = localStorage.getItem('loggedInUser');
    const jwtToken = localStorage.getItem('jwt');

    if (loggedInUser && jwtToken) {
        if (loggedInUser != 'undefined') {
            let data = JSON.parse(loggedInUser);
            return allowedList.includes(data.role.name) || allowedList.includes('all');
        }
    }
    return false
}

exports.isMenuVisible = (type = "", settingsData = 0) => {
    if (localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwt')) {
        let data = JSON.parse(localStorage.getItem('loggedInUser'));
        if (type === "home" && this.roleList('home').includes('all')) {
            return 'block';
        } else if (type === "user" && this.roleList('user').includes(data.role.name)) {
            return 'block';
        } else if (type === "organization" && this.roleList('organization').includes(data.role.name)) {
            return 'block';
        } else if (type === "reports" && this.roleList('reports').includes(data.role.name)) {
            return 'block';
        } else if (type === "reports-management" && this.roleList('reports-management').includes(data.role.name)) {
            return 'block';
        } else if (type === "streams" && this.roleList('streams').includes(data.role.name) && settingsData === 1) {
            return 'block';
        } else if (type === "surveys" && this.roleList('surveys').includes(data.role.name) && settingsData === 1) {
            return 'block';
        } else if (type === "settings" && this.roleList('settings').includes(data.role.name)) {
            return 'block';
        } else {
            return 'none';
        }
    }
    return false
}

exports.roleList = (type) => {
    // crg_user
    // client_admin_user
    // client_user
    // org_admin_user
    // org_user
    if (type === "home") {
        return ['all'];
    } else if (type === "user") {
        return ['crg_user', 'client_admin_user', 'org_admin_user'];
    } else if (type === "organization") {
        return ['crg_user', 'client_admin_user'];
    } else if (type === "reports") {
        return ['crg_user', 'client_admin_user', 'client_user', 'org_admin_user', 'org_user'];
    } else if (type === "view-report") {
        return ['crg_user', 'client_admin_user', 'org_admin_user', 'org_user'];
    } else if (type === "reports-management") {
        return ['crg_user'];
    } else if (type === "streams") {
        return ['crg_user', 'client_admin_user'];
    } else if (type === "surveys") {
        return ['crg_user', 'client_admin_user', 'client_user', 'org_admin_user', 'org_user'];
    } else if (type === "settings") {
        return ['crg_user'];
    }
}

exports.getUserData = () => {
    if (localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwt')) {
        return JSON.parse(localStorage.getItem('loggedInUser'));
    }
    return ""
};

exports.getOrganizationName = () => {
    let userData = this.getUserData();
    if (userData.organization !== null) {
        return userData.organization.name;
    }
    return "";
};
exports.accessToken = () => {
    if (localStorage.getItem('jwt') !== null && localStorage.getItem('jwt')) {
        return localStorage.getItem('jwt')
    } else {
        return "";
    }
};

exports.role = (type = "") => {
    if (localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwt')) {
        let userData = JSON.parse(localStorage.getItem('loggedInUser'));
        if (type === "") {
            return userData?.role.name || 'org_user';
        } else {
            return userData?.role.label;
        }
    }
    return 'org_user'
};
exports.getFullName = () => {
    if (localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwt')) {
        let userData = JSON.parse(localStorage.getItem('loggedInUser'));
        return userData?.first_name + " " + userData?.last_name;
    }
    return '';
};

exports.isAllowed = (type) => {
    let tmpPermission = ['new-project', 'assembly-database', 'edit-assemblies', 'invite', 'assemblies-action', 'glazing-action', 'assembly-group-action', 'add-group-assembly'];
    if (tmpPermission.includes(type) && localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwt')) {
        let userData = JSON.parse(localStorage.getItem('loggedInUser'));
        return userData?.role.name === 'admin' ? true : false;
    } else {
        return true;
    }
};

exports.isHomePage = (type) => {
    if (type === '/' || type === '/home') {
        return true;
    }
    return false;
};

exports.getSetting = (type = "") => {
    if (localStorage.getItem('settings') !== null) {
        let tmpConverted = JSON.parse(localStorage.getItem('settings'));
        if (type === "en_support") {
            return tmpConverted?.en_support_guide_url || "";
        } else if (type === "fr_support") {
            return tmpConverted?.fr_support_guide_url || "";
        } else if (type === "logo") {
            return tmpConverted?.logo_url || "";
        } else if(type === "clientName"){
            return tmpConverted?.client_name || "Ottawa";
        } else if(type === "filter1") {
            return tmpConverted?.filter_field_1 || "";
        } else if(type === "filter2") {
            return tmpConverted?.filter_field_2 || "";
        } 
    }

};


