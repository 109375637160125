/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Wrapper from '../comnon/wrapper';
import { Button, Form, Input, Row, Col, message, Select, Card, Switch, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeleteModal from '../comnon/modal';
import useTitle from '../comnon/title';
const { TextArea } = Input;

const AddReport = () => {
    const { reportId } = useParams();
    const { t } = useTranslation();
    useTitle(`${!reportId ? t('report_mngmt.add_report_title') : t('report_mngmt.edit_report_title')}`);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isReportLoading, reportList, isAddLoading, addStatus, isGetReportLoading, reportData, isToggleLoading, reportStatus, isDeleteReportLoading, deleteReportStatus } = useSelector(state => state.report);
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [visible, setVisible] = useState(false);
    const { Option } = Select;
    const [form] = Form.useForm();


    const onSwitchChange = (e) => {
        dispatch({ type: "REPORT_TOGGLE_REQUEST", reportId: reportId, toggleType: 'report' })
    }

    const loadReportGroups = (params = {}) => {
        dispatch({ type: 'REPORT_GROUP_LIST_REQUEST', formData: { status: "active" } });
    }

    useEffect(() => {
        if (reportId && !isGetReportLoading && Object.keys(reportData).length > 0) {
            setIsSwitchLoading(true);
            setChecked(reportData.archive_at === null ? true : false);
            form.setFieldsValue({
                name_en: reportData.name_en,
                name_fr: reportData.name_fr,
                description_en: reportData.description_en,
                description_fr: reportData.description_fr,
                workbook: reportData.workbook,
                dashboard: reportData.dashboard,
                height: reportData.height,
            });
            if (reportData.groups.length > 0) {
                let tmpGroupIds = reportData.groups.map(e => e.id);
                form.setFieldsValue({ groups: tmpGroupIds });
            }
            setTimeout(() => {
                setIsSwitchLoading(false);
            }, 200);
        }
    }, [isGetReportLoading, reportData]);


    useEffect(() => {
        loadReportGroups();
        if (reportId) {
            dispatch({ type: 'REPORT_DETAIL_REQUEST', id: reportId });
        }
    }, [])

    const onFinish = (values) => {
        let Obj = {};
        for (const key in values) {
            if (values[key] !== undefined) {
                if (key === "height") {
                    Obj[key] = parseInt(values[key]);
                } else {
                    Obj[key] = values[key];
                }
            }
        }
        if (reportId) {
            dispatch({ type: 'REPORT_UPDATE_REQUEST', id: reportId, formData: values });
        } else {
            dispatch({ type: 'REPORT_ADD_REQUEST', formData: Obj });
        }
    }

    const onFinishFailed = () => { };
    const onChange = (value) => { };

    const onSearch = (value) => { };

    const onDelete = (e) => {
        dispatch({ type: 'REPORT_DELETE_REQUEST', id: e, deleteType: 'report' });
    }

    useEffect(() => {
        if (!isAddLoading && addStatus === "success") {
            dispatch({ type: 'CLEAR_REPORT_STATUS' });
            if (reportId) {
                message.success({ content: t('report_mngmt.update_success_msg'), className: 'custom-success-class' });
            } else {
                message.success({ content: t('report_mngmt.add_success_msg'), className: 'custom-success-class' });
            }
            navigate(`/reports-management`);
        }
    }, [isAddLoading, addStatus]);


    useEffect(() => {
        if (!isToggleLoading && reportStatus !== "") {
            dispatch({ type: 'CLEAR_REPORT_STATUS' });
            if (reportStatus === "activated") {
                message.success({ content: t("report_mngmt.active_success_msg"), className: 'custom-success-class' });
            } else {
                message.warn({ content: t("report_mngmt.archived_success_msg"), className: 'custom-success-class' });
            }
        }
    }, [isToggleLoading, reportStatus]);

    useEffect(() => {
        if (!isDeleteReportLoading && deleteReportStatus === "success") {
            setVisible(false);
            navigate(`/reports-management`)
        }
    }, [isDeleteReportLoading, deleteReportStatus]);

    return (
        <Wrapper>
            <div className='padding-around input-class'>
                {isReportLoading && <Spin style={{ width: "100%" }} />}
                {!isReportLoading &&
                    <>
                        {reportId &&
                            <Card className='archive-card'>
                                <div className='content'>
                                    <p>{t("report_mngmt.report_status_msg")}</p>
                                    <div className='switch-delete'>
                                        <p>
                                            {isSwitchLoading && <Switch
                                                checkedChildren={<span>{t('common.active')}</span>}
                                                unCheckedChildren={<span>{t('common.archived')}</span>}
                                                defaultChecked={checked}
                                                size='large'
                                                loading={isSwitchLoading}
                                            />}
                                            {!isSwitchLoading && <p>
                                                <Switch
                                                    checkedChildren={<span>{t('common.active')}</span>}
                                                    unCheckedChildren={<span>{t('common.archived')}</span>}
                                                    defaultChecked={checked}
                                                    onChange={(e) => onSwitchChange(e)}
                                                    size='large'
                                                    loading={isSwitchLoading}
                                                />
                                            </p>}
                                        </p>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            shape="round"
                                            danger
                                            onClick={() => { setVisible(true) }}
                                            className='ml2'
                                            size={"medium"}
                                        >
                                            {t('common.delete')}
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        }
                        <Form
                            className='sign-form'
                            name='basic'
                            onFinish={onFinish}
                            layout="vertical"
                            form={form}
                            autoComplete="off"
                            onFinishFailed={onFinishFailed}>

                            <Row gutter={70} >
                                <input type="hidden" value="something" />
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('report_mngmt.english_name')}
                                        name='name_en'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t('report_mngmt.error_english_name'),
                                            }
                                        ]}
                                    >
                                        <Input placeholder={t("report_mngmt.english_name")} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('report_mngmt.french_name')}
                                        name='name_fr'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t('report_mngmt.error_french_name'),
                                            }
                                        ]}
                                    >
                                        <Input placeholder={t("report_mngmt.french_name")} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('report_mngmt.english_description')}
                                        name='description_en'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                            },
                                            {
                                                max: 500,
                                                message: t('common.max_error'),
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 3 }}
                                            placeholder={t("report_mngmt.english_description")} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('report_mngmt.french_description')}
                                        name='description_fr'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                                message: t('report_mngmt.error_french_description'),
                                            },
                                            {
                                                max: 500,
                                                message: t('common.max_error'),
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 3 }}
                                            placeholder={t("report_mngmt.french_description")} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t("report_mngmt.workbook")}
                                        name='workbook'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t('report_mngmt.error_workbook'),
                                            }
                                        ]}
                                    >
                                        <Input placeholder={t("report_mngmt.workbook")} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t("report_mngmt.dashboard")}
                                        name='dashboard'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t("report_mngmt.error_dashboard")
                                            }
                                        ]}
                                    >
                                        <Input placeholder={t("report_mngmt.dashboard")} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={70} >
                              
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t("report_mngmt.report_group")}
                                        name='groups'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t("report_mngmt.error_report_group")
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder={t("report_mngmt.select_report_group")}
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            onSearch={onSearch}
                                            loading={isReportLoading}
                                            autoComplete="none"
                                            mode="multiple"
                                            size="large"
                                        >
                                            {!isReportLoading && reportList.length > 0 && reportList.map((item) =>
                                                <Option key={item.id} value={item.id}>{item.name_en}</Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={70} className={"mt10"}>
                                <Col xs={{ span: 24, offset: 3 }} md={{ span: 24, offset: 6 }} lg={{ span: 12, offset: 9 }} >
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        shape="round"
                                        loading={isAddLoading}
                                        disabled={isAddLoading}
                                        size={"large"}>
                                        {!reportId ? t('common.confirm') : t('common.update')}
                                    </Button>
                                    <Button type="secondary" onClick={() => {
                                        dispatch({ type: "CLEAR_STATUS" });
                                        navigate('/reports-management')
                                    }} className={"ml5"} shape="round" size={"large"}>
                                        {t('common.cancel')}
                                    </Button>
                                </Col>
                            </Row>

                        </Form>
                        <DeleteModal
                            visible={visible}
                            deleteId={reportId}
                            subTitle={t("report_mngmt.delete_confirm")}
                            setVisible={() => { setVisible(false) }}
                            onDelete={(e) => onDelete(e)}
                            isDeleteLoading={isDeleteReportLoading}
                        />
                    </>}
            </div>
        </Wrapper>
    );
}

export default AddReport;