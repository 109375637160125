import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout } from '../../../utils/functions';

const SessionExpiryModal = ({visible, onContinue }) => {
    const [countdown, setCountdown] = useState(300);
    const { t, i18n } = useTranslation();
    const { isSessionExpiryRequestSending } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    function handleContinue() {
        dispatch({ type: 'REFRESH_SESSION_REQUEST' });
        onContinue();
    }

    function handleLogout() {
        logout();
        window.location.href = '/login';
    }

    useEffect(() => {
        let countdownInterval;
        if (visible) {
            countdownInterval = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        clearInterval(countdownInterval);
                        handleLogout();
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        } else {
            setCountdown(300);
        }

        return () => clearInterval(countdownInterval);
    }, [visible]);

    return (
        <Modal
            title='Session Timeout'
            visible={visible}
            onCancel={handleLogout}
            footer={[
                <Button key='continue' type='primary' onClick={handleContinue}>
                    {t('session_timeout.continue')}
                </Button>,
                <Button key='logout' onClick={handleLogout}>
                    {t('session_timeout.cancel')}
                </Button>
            ]}
        >
            <p>{t('session_timeout.message')} {Math.floor(countdown / 60)}:{String(countdown % 60).padStart(2, '0')} minutes.</p>
        </Modal>
    )
}

export default SessionExpiryModal;