import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

export default function useSettings(props) {
    const dispatch = useDispatch();
    const [isEnable, setIsEnable] = useState(false);
    const { isSettingLoading, settingsData } = useSelector(state => state.user);
    React.useEffect(() => {
        dispatch({ type: 'SETTINGS_REQUEST', formData: {} });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (!isSettingLoading && Object.keys(settingsData).length > 0) {
            setIsEnable(settingsData.enable_stream_survey === 1 ? true : false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSettingLoading, settingsData]);
    return isEnable;
}