/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Wrapper from '../comnon/wrapper';
import { Button, Form, Input, Row, Card, Switch, Col, InputNumber, Spin, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeleteModal from '../comnon/modal';
import useTitle from '../comnon/title';
import { DeleteFilled, IeCircleFilled } from '@ant-design/icons';
import FileUpload from "../comnon/fileUpload";
const { TextArea } = Input;

let tmpSurveysList = [];
let tmpSingleObject = {
    "id": 1,
    "survey_id": "",
    "name": "",
    "description_en": "",
    "description_fr": "",
    "pdf_en": "",
    "pdf_fr": "",
    "status": 1,
};
const AddStream = () => {
    const { streamId } = useParams();
    const { t, i18n } = useTranslation();
    useTitle(!streamId ? t("stream.add_title") : t("stream.edit_title"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isFileUploading, uploadFileURL, fileMsg, isGetLoading, isToggleLoading, streamData, isDeleteLoading, deleteStatus, streamStatus, isAddLoading, addStatus, isSurveyToggle,
        surveyToggleStatus } = useSelector(state => state.stream);

    const [form] = Form.useForm();



    const [noOfSurway, setNoOfSurway] = useState(0);
    const [surveyHtml, setSurveyHtml] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [currentFileKey, setCurrentFileKey] = useState("");
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (!isFileUploading && uploadFileURL !== "" && fileMsg === "success") {
            dispatch({ type: "CLEAR_STREAM_IMAGE" });
            let tmpUploadFile = { ...uploadedFiles };
            tmpUploadFile[currentFileKey] = uploadFileURL;
            setUploadedFiles(tmpUploadFile);
        }
    }, [isFileUploading, uploadFileURL, fileMsg])
    useEffect(() => {
        if (!isSurveyToggle && surveyToggleStatus === "success") {
            tmpSurveysList = [];
            setNoOfSurway(1);
            dispatch({ type: "CLEAR_STREAM" });
            dispatch({ type: 'STREAM_DETAIL_REQUEST', id: streamId });
            dispatch({ type: "CLEAR_STREAM_IMAGE" });
            // loadSurveyHtml(tmpSurveysList);
        }
    }, [isSurveyToggle, surveyToggleStatus])

    useEffect(() => {
        if (streamId && !isGetLoading && Object.keys(streamData).length > 0) {
            setDeleteId(streamId);
            form.setFieldsValue({
                name_en: streamData.name_en,
                name_fr: streamData.name_fr,
                description_en: streamData.description_en,
                description_fr: streamData.description_fr,
            });
            setIsSwitchLoading(true);
            setChecked(streamData.status === 1 ? true : false);

            form.setFieldsValue({ no_of_survay: streamData.surveys.length });
            tmpSurveysList = streamData.surveys;
            setNoOfSurway(streamData.surveys.length);
            let tmpObj = { ...uploadedFiles };
            streamData.surveys.map((item, index) => {
                tmpObj['pdfEn_' + item.id] = item.pdf_en;
                tmpObj['pdfFr_' + item.id] = item.pdf_fr;
            });
            setUploadedFiles(tmpObj)
            setTimeout(() => {
                setIsSwitchLoading(false);
            }, 100);
        } else if (!streamId) {
            loadSurveyHtml([tmpSingleObject]);
        }
    }, [isGetLoading, streamData]);

    useEffect(() => {
        if (streamId) {
            dispatch({ type: 'STREAM_DETAIL_REQUEST', id: streamId });
        } else {
            setNoOfSurway(1);
            form.setFieldsValue({ 'no_of_survay': 1 });
            // loadSurveyHtml([tmpSingleObject]);
        }
    }, []);

    const onFinish = (values) => {
        let Obj = {
            "name_en": values.name_en || "",
            "name_fr": values.name_fr || "",
            "description_en": values.description_en || "",
            "description_fr": values.description_fr || "",
        }
        let tmpSurveys = []
        if (values?.surveys && values.surveys.length > 0) {
            values.surveys.map((item, index) => {
                let tmpObj = { ...item };
                if (Object.keys(uploadedFiles).length > 0) {
                    tmpObj.pdf_en = uploadedFiles['pdfEn_' + item.id] || "";
                    tmpObj.pdf_fr = uploadedFiles['pdfFr_' + item.id] || "";
                }
                if (item.id >= 500000) {
                    delete tmpObj.id;
                }
                tmpSurveys.push(tmpObj);
            })
        }
        Obj.surveys = tmpSurveys;
        // console.log(Obj);
        // return;
        if (streamId) {
            dispatch({ type: 'STREAM_UPDATE_REQUEST', id: streamId, formData: Obj });
        } else {
            dispatch({ type: 'STREAM_ADD_REQUEST', formData: Obj });
        }
    }


    useEffect(() => {
        if (!isAddLoading && addStatus === "success") {
            dispatch({ type: 'CLEAR_STREAM' });
            if (streamId) {
                message.success({ content: t('stream.update_success_msg'), className: 'custom-success-class' });
            } else {
                message.success({ content: t('stream.add_success_msg'), className: 'custom-success-class' });
            }
            navigate(`/streams`);
        }
    }, [isAddLoading, addStatus]);


    const onFinishFailed = () => { };

    const callFunction = (data, type) => {
        if (type === "remove") {
            let tmpUploadFile = { ...uploadedFiles };
            tmpUploadFile[data] = "";
            setUploadedFiles(tmpUploadFile);
        } else {
            setCurrentFileKey(data)
        }
    }

    const onSwitchChange = (e) => {

        dispatch({ type: "STREAM_TOGGLE_REQUEST", streamId: streamId, status: e })
    }

    const onNoOfSurveyChange = (e) => {
        if (e !== "") {
            let tmpCount = e;
            let tmpSurvau = [];
            for (let index = 0; index < tmpCount; index++) {
                let obj = {};
                if (tmpSurveysList[index] === undefined) {
                    obj = {
                        "id": index + 500000,
                        "survey_id": "",
                        "name": "",
                        "description_en": "",
                        "description_fr": "",
                        "survey_version": 1,
                        "pdf_en": "",
                        "pdf_fr": "",
                        "status": 1,
                        "added": true
                    }
                } else {
                    obj = tmpSurveysList[index];
                }

                tmpSurvau.push(obj);
            }

            form.setFieldsValue({ no_of_survay: parseInt(e) });
            setNoOfSurway(parseInt(e));
            tmpSurveysList = tmpSurvau;
            // loadSurveyHtml(tmpSurvau);
        } else {
            setNoOfSurway(1);
        }
    }


    const onDelete = (e) => {
        dispatch({ type: 'STREAM_DELETE_REQUEST', id: e });
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteStatus === "success") {
            setVisible(false);
            setDeleteId("");
            navigate('/streams');
        }
    }, [isDeleteLoading, deleteStatus]);


    useEffect(() => {
        if (!isToggleLoading && streamStatus !== "") {
            dispatch({ type: 'CLEAR_STREAM' });
            if (streamStatus === "activated") {
                message.success({ content: t('stream.active_success_msg'), className: 'custom-success-class' });
            } else {
                message.warn({ content: t('stream.archived_success_msg'), className: 'custom-success-class' });
            }
        }
    }, [isToggleLoading, streamStatus]);

    const deleteRecord = (deleteIndex) => {
        let tmpList = tmpSurveysList;

        if (tmpList.length > 0) {
            setRefresh(true);
            let tmpList2 = tmpList.filter((e, index) => index !== deleteIndex);
            form.setFieldsValue({ no_of_survay: parseInt(tmpList2.length) });
            setNoOfSurway(tmpList2.length);
            // loadSurveyHtml(tmpList2);
            tmpSurveysList = tmpList2;
        }
    }

    useEffect(() => {
        if (tmpSurveysList.length !== 0 || streamId) {
            loadSurveyHtml(tmpSurveysList);
        } else {
            loadSurveyHtml([tmpSingleObject]);
        }

    }, [noOfSurway, i18n.language]);

    useEffect(() => {
        return (() => {
            // form.resetFields();
            tmpSurveysList = [];
        });
    }, [])

    const onSurveySwitchChange = (e, id) => {
        let tmp2 = [...tmpSurveysList];
        tmp2.map((item) => {
            if (item.id === id) {
                item.status = e ? 1 : 0;
            }
            return item;
        });
        tmpSurveysList = tmp2;
        if (id <= 500000) {
            dispatch({ type: "STREAM_SURVEY_TOGGLE_REQUEST", surveyId: id, status: e })
        }
    }

    const loadSurveyHtml = (tmpNoSurvay, isEdit) => {
        setSurveyHtml("");
        let tmpHtml = tmpNoSurvay.length > 0 && tmpNoSurvay.map((item, i) => {

            let survayName = ['surveys', i, 'name'];
            let survayIdName = ['surveys', i, 'survey_id'];
            let descriptionEnName = ['surveys', i, 'description_en'];
            let descriptionFrName = ['surveys', i, 'description_fr'];
            let surveyVersionName = ['surveys', i, 'survey_version'];
            let hiddenId = ['surveys', i, 'id'];
            let status = ['surveys', i, 'status'];
            let pdfEnName = `pdfEn_${item.id}`;
            let pdfFrName = `pdfFr_${item.id}`;
            let pdfEnFileName = "", pdfFrFileName = "";
            let isChecked = false;
            if (item.status !== undefined && item.status !== null) {
                isChecked = item.status === 1 ? true : false;
            }
            if (item.pdf_en !== "" && item.pdf_en !== null) {
                let tmpSplit = item.pdf_en.split('/');
                pdfEnFileName = tmpSplit.slice(-1)[0];
            }
            if (item.pdf_fr !== "" && item.pdf_fr !== null) {
                let tmpSplit = item.pdf_fr.split('/');
                pdfFrFileName = tmpSplit.slice(-1)[0];
            }
            form.resetFields([survayName, survayIdName, descriptionEnName, descriptionFrName, hiddenId, status]);
            form.setFieldsValue({
                [survayName]: item.name,
                [survayIdName]: item.survey_id,
                [descriptionEnName]: item.description_en,
                [descriptionFrName]: item.description_fr,
                [surveyVersionName]: item.survey_version !== undefined && item.survey_version !== null ? parseInt(item.survey_version) : 1,
                [hiddenId]: item.id,
                [status]: isChecked,
            })

            return (
                <div className="role-switch stream mb5" key={i}>

                    <Row gutter={70} >
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className="count-title">{t('stream.survey')} {i + 1}</p>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "right" }}>
                            <div className="delete-stream">

                                {streamId &&
                                    <div>
                                        <Switch
                                            // checkedChildren={<span>{t('common.yes')}</span>}
                                            // unCheckedChildren={<span>{t('common.no')}</span>}
                                            checkedChildren={<span>{t('common.active')}</span>}
                                            unCheckedChildren={<span>{t('common.archived')}</span>}
                                            defaultChecked={isChecked}
                                            onChange={(e) => onSurveySwitchChange(e, item.id)}
                                            size='large'
                                        // loading={isSwitchLoading || isToggleLoading}
                                        />
                                    </div>
                                }

                                {streamId && tmpNoSurvay.length !== 1 && item.added === undefined &&

                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        shape="round"
                                        danger
                                        onClick={() => deleteRecord(i)}
                                        className='ml2 mb3'
                                        size={"medium"}
                                    >
                                        {t('common.delete')}
                                    </Button>
                                }

                            </div>

                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t('stream.survey_name')}
                                name={survayName}
                                className='form-field'
                                initialValue={item.name}
                                rules={[
                                    {
                                        required: true,
                                        message: t('stream.error_survey_name'),
                                    }
                                ]}
                            >
                                <Input initialValue={"Test"} placeholder={t("stream.survey_name")} />
                            </Form.Item>
                            <Form.Item
                                style={{ display: 'none' }}
                                label={t('stream.survey_name')}
                                name={hiddenId}
                                className='form-field'
                                initialValue={item.id}
                            >
                                <Input defaultValue={item.id} />
                            </Form.Item>
                            <Form.Item
                                style={{ display: 'none' }}
                                name={status}
                                className='form-field'
                                initialValue={isChecked ? 1 : 0}
                            >
                                <Input defaultValue={isChecked ? 1 : 0} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6} style={{ paddingRight: "15px" }}>
                            <Form.Item
                                label={t('stream.survey_id')}
                                name={survayIdName}
                                className='form-field'
                                initialValue={item.survey_id}
                                rules={[
                                    {
                                        required: true,
                                        message: t('stream.error_survey_id'),
                                    }
                                ]}
                            >
                                <Input defaultValue={item.survey_id} placeholder={t("stream.survey_id")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6} style={{ paddingLeft: "15px" }}>
                            <Form.Item
                                label={t('stream.survey_version')}
                                name={surveyVersionName}
                                className='form-field'
                                initialValue={item.survey_version !== undefined && item.survey_version !== null ? parseInt(item.survey_version) : 1}
                                rules={[
                                    {
                                        required: true,
                                        message: t('stream.error_survey_version'),
                                    }
                                ]}
                            >
                                <Input min={1} type="number" defaultValue={item.survey_version !== undefined && item.survey_version !== null ? parseInt(item.survey_version) : 1} placeholder={t("stream.survey_version")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t('stream.english_description')}
                                name={descriptionEnName}
                                className='form-field'
                                initialValue={item.description_en}
                                rules={[
                                    {
                                        required: false,
                                    },
                                    {
                                        max: 500,
                                        message: t('common.max_error'),
                                    }
                                ]}
                            >
                                <TextArea
                                    defaultValue={item.description_en}
                                    autoSize={{ minRows: 2 }}
                                    placeholder={t("stream.english_description")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label={t('stream.french_description')}
                                name={descriptionFrName}
                                className='form-field'
                                initialValue={item.description_fr}
                                rules={[
                                    {
                                        required: false,
                                        message: t('stream.error_french_description'),
                                    },
                                    {
                                        max: 500,
                                        message: t('common.max_error'),
                                    }
                                ]}
                            >
                                <TextArea
                                    defaultValue={item.description_fr}
                                    autoSize={{ minRows: 2 }}
                                    placeholder={t("stream.french_description")} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="center">
                            <Form.Item>

                                <FileUpload
                                    fieldName={pdfEnName}
                                    fileName={pdfEnFileName}
                                    callFunction={(data, type) => callFunction(data, type)}
                                    label={t("stream.upload_pdf_english")}
                                    uploadFileURL={uploadedFiles[pdfEnName]} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="center">
                            <Form.Item>
                                <FileUpload
                                    fieldName={pdfFrName}
                                    fileName={pdfFrFileName}
                                    setKey={pdfFrName}
                                    callFunction={(data, type) => callFunction(data, type)}
                                    label={t("stream.upload_pdf_french")}
                                    uploadFileURL={uploadedFiles[pdfEnName]} />
                            </Form.Item>
                        </Col>
                        {/* <Card className='archive-card survey'>
                            <div className='content'>
                                <p className="update-title">{t('survey.status_msg')}</p>
                                <div className='switch-delete'>
                                    <p>
                                        {isSwitchLoading && <Switch
                                            checkedChildren={<span>{t('common.active')}</span>}
                                            unCheckedChildren={<span>{t('common.archived')}</span>}
                                            defaultChecked={checked}
                                            size='large'
                                            loading={isSwitchLoading}
                                        />}
                                        {!isSwitchLoading && <p>
                                            <Switch
                                                checkedChildren={<span>{t('common.active')}</span>}
                                                unCheckedChildren={<span>{t('common.archived')}</span>}
                                                defaultChecked={checked}
                                                onChange={(e) => onSwitchChange(e)}
                                                size='medium'
                                                loading={isSwitchLoading || isToggleLoading}
                                            />
                                        </p>}
                                    </p>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        shape="round"
                                        danger
                                        onClick={() => { setVisible(true) }}
                                        className='ml2'
                                        size={"medium"}
                                    >
                                        {t('common.delete')}
                                    </Button>
                                </div>

                            </div>
                        </Card> */}
                        {/* <div className="delete-stream">
                           
                            {streamId && tmpNoSurvay.length !== 1 && item.added === undefined &&

                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    shape="round"
                                    danger
                                    onClick={() => deleteRecord(i)}
                                    className='ml2 mb3'
                                    size={"medium"}
                                >
                                    {t('common.delete')}
                                </Button>

                            }
                        </div> */}
                    </Row>
                </div>
            )
        });
        setTimeout(() => {
            setSurveyHtml(tmpHtml);
        }, 50);
        setTimeout(() => {
            setRefresh(false);
        }, 100);
    }

    return (
        <Wrapper>
            <div className='padding-around stream-add input-class'>
                {isGetLoading && <Spin style={{ width: "100%" }} />}
                {!isGetLoading &&
                    <>
                        {streamId &&
                            <Card className='archive-card'>
                                <div className='content'>
                                    <p>{t('stream.status_msg')}</p>
                                    <div className='switch-delete'>
                                        <p>
                                            {isSwitchLoading && <Switch
                                                checkedChildren={<span>{t('common.active')}</span>}
                                                unCheckedChildren={<span>{t('common.archived')}</span>}
                                                defaultChecked={checked}
                                                size='large'
                                                loading={isSwitchLoading}
                                            />}
                                            {!isSwitchLoading && <p>
                                                <Switch
                                                    checkedChildren={<span>{t('common.active')}</span>}
                                                    unCheckedChildren={<span>{t('common.archived')}</span>}
                                                    defaultChecked={checked}
                                                    onChange={(e) => onSwitchChange(e)}
                                                    size='large'
                                                    loading={isSwitchLoading || isToggleLoading}
                                                />
                                            </p>}
                                        </p>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            shape="round"
                                            danger
                                            onClick={() => { setVisible(true) }}
                                            className='ml2'
                                            size={"medium"}
                                        >
                                            {t('common.delete')}
                                        </Button>
                                    </div>

                                </div>
                            </Card>
                        }
                        <Form
                            className='sign-form'
                            name='basic'
                            onFinish={onFinish}
                            layout="vertical"
                            form={form}
                            autoComplete="off"
                            onFinishFailed={onFinishFailed}>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('stream.english_name')}
                                        name='name_en'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t('stream.error_english_name'),
                                            }
                                        ]}
                                    >
                                        <Input placeholder={t("stream.english_name")} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('stream.french_name')}
                                        name='name_fr'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: true,
                                                message: t('stream.error_french_name'),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t("stream.french_name")} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('stream.english_description')}
                                        name='description_en'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                            },
                                            {
                                                max: 500,
                                                message: t('common.max_error'),
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 3 }}
                                            placeholder={t("stream.english_description")} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="red">
                                    <Form.Item
                                        label={t('stream.french_description')}
                                        name='description_fr'
                                        className='form-field'
                                        rules={[
                                            {
                                                required: false,
                                                message: t('stream.error_french_description'),
                                            },
                                            {
                                                max: 500,
                                                message: t('common.max_error'),
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 3 }}
                                            placeholder={t("stream.french_description")} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={70} >
                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className="red">
                                    <Form.Item
                                        label={t('stream.no_of_survay')}
                                        name='no_of_survay'
                                        className='form-field'
                                        initialValue={noOfSurway}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('stream.error_no_of_survay'),
                                            },
                                            {
                                                pattern: new RegExp(/^[0-9]*$/),
                                                message: t("stream.error_numeric"),
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            className="no-of-text"
                                            // defaultValue={noOfSurway}
                                            value={noOfSurway}
                                            onChange={(e) => onNoOfSurveyChange(e)}
                                            type="number"
                                            placeholder={t("stream.french_name")} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 15, sm: 20, md: 35, lg: 70 }} >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="red report-user-select connection">
                                    <Form.Item label={t('stream.survey_connection')}>
                                        {refresh && <p>Loading...</p>}
                                        {!refresh && surveyHtml}
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row gutter={70} className={"mt10"}>
                                <Col xs={{ span: 24, offset: 3 }} md={{ span: 24, offset: 6 }} lg={{ span: 12, offset: 9 }} >
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        shape="round"
                                        loading={isAddLoading}
                                        disabled={isAddLoading}
                                        size={"large"}>
                                        {!streamId ? t('common.confirm') : t('common.update')}
                                    </Button>
                                    <Button type="secondary" onClick={() => {
                                        dispatch({ type: "CLEAR_STREAM" });
                                        navigate('/streams')
                                    }} className={"ml5"} shape="round" size={"large"}>
                                        {t('common.cancel')}
                                    </Button>
                                </Col>
                            </Row>

                        </Form>
                    </>
                }
                <DeleteModal
                    visible={visible}
                    deleteId={deleteId}
                    subTitle={t("stream.delete_confirm")}
                    setVisible={() => { setVisible(false) }}
                    onDelete={(e) => onDelete(e)}
                    isDeleteLoading={isDeleteLoading}
                />
            </div>
        </Wrapper >
    );
}

export default AddStream;
