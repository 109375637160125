import React from 'react'
import AuthWrapper from '../authwrapper';
import ResetPasswordComponent from './resetPasswordComponent';
import QueueAnim from 'rc-queue-anim';

function ResetPassword(props) {
    return (
        <QueueAnim
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <AuthWrapper>
                <ResetPasswordComponent type={props.type} />
            </AuthWrapper>
        </QueueAnim>

    )
}
export default ResetPassword;