import React, { useState, useEffect }  from 'react';
import {Card, Dropdown, Menu} from 'antd';
import { useTranslation } from 'react-i18next';
import AppAnimateGroup from '../auth/AppAnimateGroup';
import RBCLogo from '../../assets/crgLogo.png';

import './style.scss';
import { getSetting } from '../../utils/functions';

function AuthWrapper({ children }) {
    const [currentLanguage, setCurrentLanguage] = useState('en');
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        setCurrentLanguage(lng);
        localStorage.setItem('selectedLanguage', lng)
        callForChange(lng);
    }
    const callForChange = (lng) => {
        i18n.changeLanguage(lng);
    }
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <span onClick={() => changeLanguage("en")}>
                            English (EN)
                        </span>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <span onClick={() => changeLanguage("fr")}>
                            French (FR)
                        </span>
                    ),
                }
            ]}
        />
    );

    useEffect(() => {
        if (!localStorage.getItem('selectedLanguage')) {
            setCurrentLanguage('en');
            localStorage.setItem('selectedLanguage', 'en');
        } else {
            setCurrentLanguage(localStorage.getItem('selectedLanguage'));
        }
    }, []);
    return (
        <AppAnimateGroup
            type='scale'
            animateStyle={{ flex: 1 }}
            delay={0}
            interval={10}
            duration={200}>

            <div className='auth-wrap auth' key={'wrap'}>
                <Card className='auth-card'>
                    <div className='auth-main-content'>
                        <div style={{
                            position: "absolute",
                            right: '20px',
                            top: '20px',
                            cursor: 'pointer'
                        }}
                        >
                            <Dropdown overlay={menu} trigger="hover" placement="bottom">
                                <span className='header-menu'>{currentLanguage.toUpperCase()}</span>
                            </Dropdown>
                        </div>
                        <div className='auth-card-header'>
                            <img src={RBCLogo} alt="CRG" className='rbc-logo img1'></img>
                            <div class="vl"></div>
                            {getSetting('logo') !== "" &&
                                <div className='img2-parent'>
                                    <img src={getSetting('logo')} alt="CRG" className='rbc-logo img2'></img>
                                </div>
                            }
                        </div>
                        {children}
                    </div>
                </Card>
            </div>
        </AppAnimateGroup>

    )
}
export default AuthWrapper;
