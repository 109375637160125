import { combineReducers } from 'redux';
import auth from './auth';
import report from './reports';
import user from './user';
import organization from './organization';
import stream from './stream';
import survey from './survey';

export default combineReducers({
    auth,
    report,
    user,
    organization,
    stream,
    survey
})