const initialState = {
    isLoading: false,
    streamList: [],

    isAddLoading: false,
    addStatus: "",

    isGetLoading: false,
    streamData: {},

    isDeleteLoading: false,
    deleteStatus: "",

    isToggleLoading: false,
    streamStatus: "",

    error: "",

    isFileUploading: false,
    uploadFileURL: "",
    fileMsg: "",
    isSurveyToggle: false,
    surveyToggle: ""

};

const streamReducer = (state = initialState, action = {}) => {

    switch (action.type) {
        case "STREAM_LIST_REQUEST":
            state = {
                ...state,
                isLoading: true,
                streamList: []

            };
            break;

        case "STREAM_LIST_SUCCESS":
            state = {
                ...state,
                isLoading: false,
                streamList: action.data
            };
            break;

        case "STREAM_LIST_ERROR":
            state = {
                ...state,
                isLoading: false,
                streamList: [],
                error: action.error,
            };
            break;


        case "CLEAR_STREAM_DATA":
            state = {
                ...state,
                isLoading: false,
                streamList: [],
            };
            break;

        /******************************/
        case "STREAM_ADD_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                addStatus: ''

            };
            break;

        case "STREAM_ADD_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                addStatus: "success"
            };
            break;

        case "STREAM_ADD_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                addStatus: ""
            };
            break;

        /******************************/
        case "STREAM_UPDATE_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                addStatus: ''

            };
            break;

        case "STREAM_UPDATE_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                addStatus: "success"
            };
            break;

        case "STREAM_UPDATE_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                addStatus: ""
            };
            break;


        /******************************/
        case "STREAM_DETAIL_REQUEST":
            state = {
                ...state,
                isGetLoading: true,
                streamData: {}

            };
            break;

        case "STREAM_DETAIL_SUCCESS":
            state = {
                ...state,
                isGetLoading: false,
                streamData: action.data
            };
            break;

        case "STREAM_DETAIL_ERROR":
            state = {
                ...state,
                isGetLoading: false,
                streamData: {},
                error: action.error,
            };
            break;

        /******************************/
        case "STREAM_DELETE_REQUEST":
            state = {
                ...state,
                isDeleteLoading: true,
                deleteStatus: ""

            };
            break;

        case "STREAM_DELETE_SUCCESS":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteStatus: "success"
            };
            break;

        case "STREAM_DELETE_ERROR":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteStatus: ""
            };
            break;

        /******************************/
        case "STREAM_TOGGLE_REQUEST":
            state = {
                ...state,
                isToggleLoading: true,
                streamStatus: ""

            };
            break;

        case "STREAM_TOGGLE_SUCCESS":
            state = {
                ...state,
                isToggleLoading: false,
                streamStatus: action.data
            };
            break;

        case "STREAM_TOGGLE_ERROR":
            state = {
                ...state,
                isToggleLoading: false,
                streamStatus: ""
            };
            break;

        /******************************/
        case "STREAM_SURVEY_TOGGLE_REQUEST":
            state = {
                ...state,
                isSurveyToggle: true,
                surveyToggleStatus: ""

            };
            break;

        case "STREAM_SURVEY_TOGGLE_SUCCESS":
            state = {
                ...state,
                isSurveyToggle: false,
                surveyToggleStatus: 'success'
            };
            break;

        case "STREAM_SURVEY_TOGGLE_ERROR":
            state = {
                ...state,
                isSurveyToggle: false,
                surveyToggleStatus: ""
            };
            break;

        // --- Update image stepi
        case "UPLOAD_IMAGE_REQUEST":
            state = {
                ...state,
                isFileUploading: true,
                uploadFileURL: "",
                fileMsg: ""
            };
            break;

        case "UPLOAD_IMAGE_SUCCESS":
            state = {
                ...state,
                isFileUploading: false,
                uploadFileURL: action.data,
                fileMsg: "success"
            };
            break;

        case "UPLOAD_IMAGE_ERROR":
            state = {
                ...state,
                isFileUploading: false,
                uploadFileURL: "",
                fileMsg: ""
            };
            break;


        case "CLEAR_STREAM":
            state = {
                ...state,
                addStatus: "",
                deleteStatus: "",
                streamData: {},
                streamStatus: "",
                surveyToggleStatus:"",
                uploadFileURL: "",
                fileMsg: "",
                addStatus: ""
            };
            break;

        case "CLEAR_STREAM_IMAGE":
            state = {
                ...state,
                uploadFileURL: "",
                fileMsg: "",
            };
            break;



        default:

    }
    return state;
};

export default streamReducer;
