/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Input } from 'antd';
import { PlusOutlined, EditFilled, DeleteFilled, DownloadOutlined } from '@ant-design/icons';
import { Space, Table, Tag, message, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";
import Wrapper from '../comnon/wrapper';
import DeleteModal from '../comnon/modal.js';
import useTitle from '../comnon/title';
import { useTranslation } from "react-i18next";
import { PER_PAGE, ROLE_NAME } from '../../../utils/constant'
import { role, getOrganizationName, getSetting } from '../../../utils/functions';
import { CSVLink } from "react-csv";
const { Search } = Input;
const { Column } = Table;


const Users = () => {
    const { t } = useTranslation();
    useTitle(t("user.view_title"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { isLoading, userList, isDeleteLoading, deleteStatus, isExportLoading, exportData } = useSelector(state => state.user);
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [tmpExportData, setTmpExportData] = useState("");
    const [isPageLoading, setIsPageLoading] = useState(isExportLoading);
    const [searchValue, setSearchValue] = useState("");

    const [currentTab, setCurrentTab] = useState('')


    useEffect(() => {
        if (searchParams.get('tab') !== null) {
            onChangeTab(searchParams.get('tab'))
        } else {
            loadUsers({}, roleType());
        }
    }, [searchParams])

    const roleType = (type = "") => {
        let tmpRole = {};
        if ([ROLE_NAME.CRG_USER].includes(role())) {
            setCurrentTab('CRG');
            tmpRole = { role: ROLE_NAME.CRG_USER };
        } else if ([ROLE_NAME.CLIENT_ADMIN, ROLE_NAME.CLIENT_USER].includes(role())) {
            tmpRole = { role: ROLE_NAME.CLIENT_ADMIN + "," + ROLE_NAME.CLIENT_USER };
            setCurrentTab('Client');
        } else if ([ROLE_NAME.ORG_ADMIN, ROLE_NAME.ORG_USER].includes(role())) {
            tmpRole = { role: ROLE_NAME.ORG_ADMIN + "," + ROLE_NAME.ORG_USER, organization: getOrganizationName() };
            setCurrentTab('Organization');
        }
        return tmpRole;
    }


    const loadUsers = (params = {}, tmprole = "") => {
        if (tmprole !== '') {
            tmprole = { ...tmprole }
        }
        let tmpparams = { ...tmprole, ...params }
        dispatch({ type: 'USERS_LIST_REQUEST', formData: tmpparams });
    }

    useEffect(() => {
        if (!isLoading && userList.length > 0) {
            let tmpOrgList = [];
            userList.map((item) => {
                let Obj = {};
                Obj.name = item.first_name + " " + item.last_name;
                Obj.email = item.email;
                Obj.usertype = item.role.label;
                Obj.organization = item.organization !== null ? item.organization.name : "";
                Obj.key = item.id;
                Obj.status = item.archived_at === null ? "Active" : "Archived";
                Obj.other = { ...item.client_id };
                Obj.tab = currentTab;
                Obj.isDisabled = (item.email === "admin@crg.test") ? true : false;
                tmpOrgList.push(Obj);
                return true;
            });
            setData(tmpOrgList);
        } else if (!isLoading && userList.length === 0) {
            setData([]);
        }
    }, [isLoading, userList])

    const onSearch = (e) => {
        let tmpParam = {};
        if (currentTab === "CRG") {
            tmpParam = { role: ROLE_NAME.CRG_USER };
        } else if (currentTab === "Client") {
            tmpParam = { role: ROLE_NAME.CLIENT_ADMIN + "," + ROLE_NAME.CLIENT_USER };
        } else if (currentTab === "Organization") {
            if (role() === ROLE_NAME.ORG_ADMIN || role() === ROLE_NAME.ORG_USER) {
                tmpParam = { role: ROLE_NAME.ORG_ADMIN + "," + ROLE_NAME.ORG_USER, organization: getOrganizationName() };
            } else {
                tmpParam = { role: ROLE_NAME.ORG_ADMIN + "," + ROLE_NAME.ORG_USER };
            }
        }
        setSearchValue(e);
        loadUsers({ ...tmpParam, q: e });
    }

    const onDelete = (e) => {
        dispatch({ type: 'USER_DELETE_REQUEST', id: deleteId });
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteStatus === "success") {
            setVisible(false);
            dispatch({ type: 'CLEAR_STATUS' });
            message.success({ content: t("user.delete_success_msg"), className: 'custom-success-class' });
            loadUsers({}, roleType());

        }
    }, [isDeleteLoading, deleteStatus]);

    const onChangeTab = (tabName) => {
        setCurrentTab(tabName);
        setSearchValue("");
        let tmpParam = {};
        if (tabName === "CRG") {
            tmpParam = { role: ROLE_NAME.CRG_USER };
        } else if (tabName === "Client") {
            tmpParam = { role: ROLE_NAME.CLIENT_ADMIN + "," + ROLE_NAME.CLIENT_USER };
        } else if (tabName === "Organization") {
            if (role() === ROLE_NAME.ORG_ADMIN || role() === ROLE_NAME.ORG_USER) {
                tmpParam = { role: ROLE_NAME.ORG_ADMIN + "," + ROLE_NAME.ORG_USER, organization: getOrganizationName() };
            } else {
                tmpParam = { role: ROLE_NAME.ORG_ADMIN + "," + ROLE_NAME.ORG_USER };
            }
        }
        loadUsers({}, tmpParam)
    }

    const exportUser = () => {
        setIsPageLoading(true);

        let tmpParams = "";
        if ([ROLE_NAME.CRG_USER].includes(role())) {
            tmpParams = { role: `${ROLE_NAME.CRG_USER},${ROLE_NAME.CLIENT_ADMIN},${ROLE_NAME.CLIENT_USER},${ROLE_NAME.ORG_ADMIN},${ROLE_NAME.ORG_USER}` };
        } else if ([ROLE_NAME.CLIENT_ADMIN, ROLE_NAME.CLIENT_USER].includes(role())) {
            tmpParams = { role: `${ROLE_NAME.CLIENT_ADMIN},${ROLE_NAME.CLIENT_USER},${ROLE_NAME.ORG_ADMIN},${ROLE_NAME.ORG_USER}` };
        } else if ([ROLE_NAME.ORG_ADMIN, ROLE_NAME.ORG_USER].includes(role())) {
            tmpParams = { role: `${ROLE_NAME.ORG_ADMIN},${ROLE_NAME.ORG_USER}`, organization: getOrganizationName() };
        }
        dispatch({ type: 'USER_EXPORT_REQUEST', params: tmpParams });
    }

    useEffect(() => {
        if (!isExportLoading && exportData !== "") {
            setTmpExportData(exportData);
            setTimeout(() => {
                dispatch({ type: "CLEAR_STATUS" });
                setIsPageLoading(isExportLoading);
                document.getElementById('exportToCSVLink').click();
            }, 1000);
        }
    }, [isExportLoading, exportData])

    return (
        <Wrapper title="User View" >
            <div className='padding-around user-view'>
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} className="red">
                        <div className='nav-add-btn'>
                            <div>
                                <Button id="add-user" type="primary" shape="circle" onClick={() => navigate('/user/add')} icon={<PlusOutlined />} size={"medium"} />
                                <label htmlFor="add-user" className="nav-add-label">{t('user.add')}</label>
                            </div>
                            <div className='ml15'>
                                <Button id="export-user"
                                    className='export-btn'
                                    type="primary"
                                    shape="circle"
                                    loading={isPageLoading}
                                    disabled={isPageLoading}
                                    onClick={() => exportUser()}
                                    icon={<DownloadOutlined />} size={"medium"} />
                                <label htmlFor="export-user" className="nav-add-label">{t('common.export')}</label>
                            </div>

                        </div>

                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="green">
                        <Search
                            value={searchValue}
                            allowClear
                            placeholder={t("common.search_text")}
                            onSearch={onSearch}
                            onChange={(e) => { setSearchValue(e.target.value); }}
                            enterButton />
                    </Col>
                </Row>
                <CSVLink id="exportToCSVLink" filename="Users.csv" data={tmpExportData} />
                {/* {tmpExportData !== "" &&
                < CSVLink data={tmpExportData} separator={","}>
                    Download me
                </CSVLink>
            } */}
                <Row className='listing-table mt10'>
                    <div className='tab'>
                        {role() === ROLE_NAME.CRG_USER &&
                            <p className={`b-right ${currentTab === 'CRG' ? 'active' : ''}`} onClick={() => onChangeTab('CRG')}><span>{t("tab.crg")}</span></p>
                        }
                        {(role() === ROLE_NAME.CRG_USER || role() === ROLE_NAME.CLIENT_ADMIN) &&
                            <p className={`b-right ${currentTab === 'Client' ? 'active' : ''}`} onClick={() => onChangeTab('Client')}><span>{getSetting('clientName')}</span></p>
                        }
                        <p className={`b-right ${currentTab === 'Organization' ? 'active' : ''}`} onClick={() => onChangeTab('Organization')}><span>{t("tab.organization")}</span></p>
                        {role() === ROLE_NAME.CRG_USER &&
                            <p className={`${currentTab === 'All' ? 'active' : ''}`} onClick={() => onChangeTab('All')}><span>{t("tab.all")}</span></p>
                        }
                    </div>
                    <Table
                        style={{ width: "100%" }}
                        // columns={columns}
                        dataSource={data}
                        loading={isLoading}
                        scroll={{ x: "max-content" }}
                        pagination={{
                            pageSize: PER_PAGE,
                            showTotal: (total, range) => `${range[0]}-${range[1]} ${t("common.of")} ${total} ${t("common.records")}`,
                            hideOnSinglePage: true,
                        }}
                    >
                        {/* <Column title="Age" dataIndex="age" key="age" /> */}
                        <Column
                            title={t('common.name')}
                            dataIndex='name'
                            key='name'
                            sortDirections={['descend', 'ascend']}
                            sorter={(a, b) => a.name.localeCompare(b.name)}
                            render={(text) => <span>{text}</span>} />
                        <Column
                            title={t('common.email')}
                            dataIndex='email'
                            key='email'
                            sorter={(a, b) => a.email.localeCompare(b.email)}
                            render={(text) => <span>{text}</span>} />
                        <Column
                            title={t('user.user_type')}
                            dataIndex='usertype'
                            key='usertype'
                            sorter={(a, b) => a.usertype.localeCompare(b.usertype)}
                            render={(text) => <span>{text}</span>} />
                        {currentTab === "Organization" &&
                            <Column
                                title={t('organization.title')}
                                dataIndex='organization'
                                key='organization'
                                sorter={(a, b) => a.organization.localeCompare(b.organization)}
                                render={(text) => <span>{text}</span>} />
                        }
                        <Column
                            title={t('common.status')}
                            key='status'
                            dataIndex='status'
                            filters={[
                                {
                                    text: 'Active',
                                    value: 'Active',
                                },
                                {
                                    text: 'Archived',
                                    value: 'Archived',
                                }
                            ]}
                            filterMode='tree'
                            onFilter={(value, record) => record.status.startsWith(value)}
                            render={(_, { status }) => {
                                let color = (status !== 'Active') ? "default" : "success";
                                return (
                                    <Tag color={color} key={status}>
                                        {status.toUpperCase()}
                                    </Tag>)
                            }}
                        />

                        <Column
                            title={t('common.action')}
                            key='action'
                            render={(_, record) => {
                                return (
                                    <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                        <Tooltip placement="top" title={t('user.edit_tooltip')}>
                                            <EditFilled disabled={record.isDisabled} className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`} onClick={() => navigate(`/user/edit/${record.key}?tab=${record.tab}`)} style={{ fontSize: "18px" }} />
                                        </Tooltip>
                                        <Tooltip placement="top" color={"red"} title={t('user.delete_tooltip')}>
                                            <DeleteFilled
                                                disabled={record.isDisabled}
                                                onClick={() => {
                                                    setDeleteId(record.key);
                                                    setVisible(true)
                                                }}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }} />
                                        </Tooltip>
                                    </Space>
                                )
                            }}
                        />
                    </Table>
                    <DeleteModal
                        visible={visible}
                        subTitle={t('user.delete_confirm')}
                        setVisible={() => { setDeleteId(""); setVisible(false) }}
                        onDelete={() => onDelete()}
                        isDeleteLoading={isDeleteLoading}
                    />
                </Row>
            </div>

        </Wrapper >
    )
}

export default Users;
