/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Input, Popover } from 'antd';
import { PlusOutlined, EditFilled, DeleteFilled, DownloadOutlined } from '@ant-design/icons';
import { Space, Table, Tag, message, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Wrapper from '../comnon/wrapper';
import DeleteModal from '../comnon/modal.js';
import useTitle from '../comnon/title';
import { API_URL, BASE_BACKEND_URL, PER_PAGE, ROLE, ROLE_NAME, SURVEY_LINK } from '../../../utils/constant';
import { useTranslation } from "react-i18next";
import { accessToken } from '../../../utils/functions';
const { Search } = Input;
const { Column } = Table;


const Surveys = () => {

    const { t, i18n } = useTranslation();
    useTitle(t("survey.view_title"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, surveyList, isDeleteLoading, deleteStatus } = useSelector(state => state.survey);
    const [data, setData] = useState([]);

    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    useEffect(() => {
        loadStream();
    }, []);

    const loadStream = (params = {}) => {
        dispatch({ type: 'SURVEY_LIST_REQUEST', formData: params });
    }

    useEffect(() => {

        if (!isLoading && surveyList.length > 0) {
            let tmpOrgList = [];
            surveyList.map((itemMain) => {
                if (itemMain.programSurveys !== undefined && itemMain.programSurveys.length > 0) {
                    itemMain.programSurveys.map((item) => {
                        let Obj = {};
                        // Obj.name = i18n.language === "fr" ? item.name_fr : item.name_en;
                        Obj.name = itemMain.name_en;
                        Obj.id = itemMain.id;
                        Obj.program_id = itemMain.id;
                        Obj.key = itemMain.id + "main" + item.id;
                        Obj.program_status = itemMain.status === 1 ? "Active" : "Archived";
                        Obj.survey_type = item?.survey?.name || "";
                        Obj.survey_id = item?.survey?.survey_id || "";
                        Obj.survey_version = item?.survey?.survey_version || 1;
                        Obj.organization_id = itemMain?.organization?.organization_identifier || "";
                        Obj.organization_name = itemMain?.organization?.name || "";
                        Obj.survey_status = item?.survey?.status === 1 ? 'Active' : "Archived";
                        Obj.survey_record_id = item?.id;
                        tmpOrgList.push(Obj);
                    });
                } else {
                    if (itemMain.programSurveys !== undefined && itemMain.programSurveys === 0) {
                        let Obj = {};
                        Obj.name = itemMain.name_en;
                        Obj.id = itemMain.id;
                        Obj.key = itemMain.id + "main5555";
                        Obj.program_status = itemMain.status === 1 ? "Active" : "Archived";
                        Obj.survey_type = "";
                        Obj.survey_id = "";
                        Obj.survey_status = "";
                        Obj.survey_record_id = "";
                        tmpOrgList.push(Obj);
                    }
                }
            });

            setData(tmpOrgList);
        } else if (!isLoading && surveyList.length === 0) {
            setData([]);
        }
    }, [isLoading, surveyList])

    const onSearch = (e) => {
        loadStream({ search: e });
    }

    const onDelete = (e) => {
        dispatch({ type: 'SURVEY_DELETE_REQUEST', id: deleteId });
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteStatus === "success") {
            setVisible(false);
            message.success({ content: t('survey.delete_success_msg'), className: 'custom-success-class' });
            dispatch({ type: 'CLEAR_STATUS' });
            dispatch({ type: 'CLEAR_SURVEY' });
            loadStream();
        }
    }, [isDeleteLoading, deleteStatus]);

    const content = (text) => {
        return (<p style={{ marginBottom: "0px" }}>{text}</p>)
    }

    const exportSurvey = () => {
        window.open(`${API_URL}programs/export?token=${accessToken()}`)
    }
    return (
        <Wrapper title="survey_id View" >
            <div className='padding-around'>
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} className="red">
                        <div className='nav-add-btn'>
                            <div>
                                <Button id="add-survey" type="primary" shape="circle" onClick={() => navigate('/survey/add')} icon={<PlusOutlined />} size={"medium"} />
                                <label htmlFor="add-survey" className="nav-add-label">{t('survey.add')}</label>
                            </div>
                            <div className='ml15'>
                                <Button id="export-survey"
                                    className='export-btn'
                                    type="primary"
                                    shape="circle"
                                    // loading={isPageLoading}
                                    // disabled={isPageLoading}
                                    onClick={() => exportSurvey()}
                                    icon={<DownloadOutlined />}
                                    size={"medium"} />
                                <label htmlFor="export-survey" className="nav-add-label">{t('common.export')}</label>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="green">
                        <Search allowClear placeholder={t("common.search_text")} onSearch={onSearch} enterButton />
                    </Col>
                </Row>

                <Row className='listing-table mt10'>
                    <Table
                        loading={isLoading}
                        style={{ width: "100%" }}
                        dataSource={data}
                        pagination={{
                            pageSize: 100
                        }}
                    >
                        {(ROLE !== ROLE_NAME.ORG_ADMIN && ROLE !== ROLE_NAME.ORG_USER) &&
                            <Column
                                title={t("survey.organization")}
                                dataIndex='organization_name'
                                key='organization_name'
                                render={(text) => <b>{text}</b>}
                            />
                        }
                        <Column
                            title={t("survey.program_name")}
                            dataIndex='name'
                            key='name'
                            render={(text) => <b>{text}</b>}
                        />
                        <Column
                            title={t("survey.survey_type")}
                            dataIndex='survey_type'
                            key='survey_type'
                            render={(text) => <b>{text}</b>}
                        />
                        <Column
                            title={t("survey.survey_status")}
                            key='survey_status'
                            dataIndex='survey_status'
                            filters={[
                                {
                                    text: 'Active',
                                    value: 'Active',
                                },
                                {
                                    text: 'Archived',
                                    value: 'Archived',
                                }
                            ]}
                            filterMode='tree'
                            onFilter={(value, record) => record.status.startsWith(value)}
                            render={(_, { survey_status }) => {
                                let color = (survey_status !== 'Active') ? "default" : "success";
                                return (
                                    <Tag color={color} key={survey_status}>
                                        {survey_status.toUpperCase()}
                                    </Tag>)
                            }}
                        />
                        <Column
                            title={t("common.status")}
                            key='program_status'
                            dataIndex='program_status'
                            filters={[
                                {
                                    text: 'Active',
                                    value: 'Active',
                                },
                                {
                                    text: 'Archived',
                                    value: 'Archived',
                                }
                            ]}
                            filterMode='tree'
                            onFilter={(value, record) => record.status.startsWith(value)}
                            render={(_, { program_status }) => {
                                let color = (program_status !== 'Active') ? "default" : "success";
                                return (
                                    <Tag color={color} key={program_status}>
                                        {program_status.toUpperCase()}
                                    </Tag>)
                            }}
                        />
                        <Column
                            title={t("common.action")}
                            key='action'
                            render={(_, record) => (
                                <Space size="middle">

                                    <Popover placement="top"
                                        overlayClassName="password-tooltip"
                                        content={() => content("Copied!")}
                                        trigger={'click'}
                                    >
                                        <Button type="primary" shape="round" size={"default"}
                                            onClick={() => navigator.clipboard.writeText(`${SURVEY_LINK}752/${record.survey_id}?list=${record.survey_version}&OrgID=${record.organization_id}&ProgID=${record.program_id}`)}
                                        >
                                            English
                                        </Button>
                                    </Popover>
                                    <Popover placement="top"
                                        overlayClassName="password-tooltip"
                                        content={() => content("Copied!")}
                                        trigger={'click'}
                                    >
                                        <Button type="primary" shape="round" size={"default"}
                                            onClick={() => navigator.clipboard.writeText(`${SURVEY_LINK}752/${record.survey_id}?list=${record.survey_version}&OrgID=${record.organization_id}&ProgID=${record.program_id}&decLang=canadian`)}
                                        >
                                            French
                                        </Button>
                                    </Popover>
                                    <Tooltip placement="top" title={t("survey.edit_tooltip")}>
                                        <EditFilled className='cursor' onClick={() => navigate(`/survey/edit/${record.id}`)} style={{ fontSize: "18px" }} />
                                    </Tooltip>
                                    {/* <Tooltip placement="top" color={"red"} title={t('survey.delete_tooltip')}>
                                        <DeleteFilled
                                            disabled={record.isDisabled}
                                            onClick={() => {
                                                setDeleteId(record.program_id);
                                                setVisible(true)
                                            }}
                                            className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                            style={{ fontSize: "18px" }} />
                                    </Tooltip> */}
                                </Space>
                            )}
                        />
                    </Table>
                    <DeleteModal
                        visible={visible}
                        subTitle={t("survey.delete_confirm")}
                        setVisible={() => { setDeleteId(""); setVisible(false) }}
                        onDelete={() => onDelete()}
                        isDeleteLoading={isDeleteLoading}
                    />
                </Row>
            </div>
        </Wrapper>
    )
};

export default Surveys;                    
