import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
export default function useTitle(props) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch({ type: "UPDATE_TITLE", data: props || "HOME PAGE" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);
    return true;
}