import React from 'react'
import AuthWrapper from '../authwrapper';
import ForgetPasswordComponent from './forgetPasswordComponent';
import QueueAnim from 'rc-queue-anim';

function ForgetPassword() {
    return (
        <QueueAnim
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
            >
            <AuthWrapper>
                <ForgetPasswordComponent />
            </AuthWrapper>
        </QueueAnim>

    )
}
export default ForgetPassword;