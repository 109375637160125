import React from "react";
import { VERSION_NUMBER } from '../../../utils/constant'

const Version = () => (
    <div className="fix-version">
        V-{VERSION_NUMBER}
    </div>
);
;

export default Version;
