import { Breadcrumb } from 'antd';
import React, { } from 'react';
import { useSelector } from 'react-redux';
const BreadcrumbLayout = () => {
    const { title } = useSelector(state => state.user);
    return (
        <Breadcrumb
            style={{
                margin: '16px 0',
            }}
        >
            <span className='main-title'>{title}</span>
            {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>List</Breadcrumb.Item>
        <Breadcrumb.Item>App</Breadcrumb.Item> */}
        </Breadcrumb>
    )
};

export default BreadcrumbLayout;