/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Row, Col } from 'antd';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import PasswordComponent from './passwordComponent';
function ResetPasswordComponent(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const [type, setType] = useState("")
    const [fieldValue, setFieldValue] = useState({})

    const { isRequestSending, statusMsg, isResendLoading, resendMsg } = useSelector(state => state.auth);

    const onFinish = (values) => {
        setFieldValue(values)
        dispatch({ type: 'SET_PASSWORD_REQUEST', formData: values, pageType: type });
    };

    useEffect(() => {
        if (!isRequestSending && statusMsg === "success") {
            if (type === "set") {
                message.success(t("login.verified"));
            } else {
                message.success(t("login.reset_msg"));
            }
            dispatch({ type: "CLEAR_INVITE" });
            navigate('/login');
        } else if (!isRequestSending && statusMsg?.errors) {
            let tmpObj = statusMsg.errors !== undefined ? statusMsg.errors : statusMsg?.message;
            let tmpError = [];
            for (const property in tmpObj) {
                tmpError.push({
                    name: property,
                    value: fieldValue[property],
                    errors: tmpObj[property]
                })
            }
            form.setFields(tmpError);
        } else if (!isRequestSending && statusMsg?.message) {
            message.error({ content: statusMsg?.message, className: 'error-class', duration: 3 });
        }
        dispatch({ type: "CLEAR_INVITE" });
    }, [isRequestSending, statusMsg]);


    useEffect(() => {
        if (!isResendLoading && resendMsg === "success") {
            message.success(t("login.resend_msg"));
        } else if (resendMsg?.message || resendMsg?.errors) {
            message.error({ content: statusMsg?.message || "Something went wrong", className: 'error-class', duration: 3 });
        }
        dispatch({ type: "CLEAR_INVITE" });
    }, [isResendLoading, resendMsg]);

    const onFinishFailed = (errorInfo) => { console.log('Failed:', errorInfo) };

    useEffect(() => {
        setType(props.type);
    }, [])

    const resendCode = (e) => {
        e.preventDefault()
        dispatch({ type: 'RESEND_CODE_REQUEST', formData: { email: searchParams.get('email') !== null ? searchParams.get('email') : "" } });
    }


    return (
        <div className='sign'>
            <div className='sign-content'>
                <Form
                    className='sign-form'
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: false }]}
                        initialValue={searchParams.get('email') !== null ? searchParams.get('email') : ""}
                    >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        name="pin"
                        rules={[
                            {
                                required: true,
                                message: t("login.error_pin"),
                            },
                        ]}
                    >
                        <Input placeholder={t("login.pin")} type={"number"} showCount maxLength={5} />
                    </Form.Item>
                    <PasswordComponent />
                    <Form.Item
                        name="password_confirmation"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: t("login.error_confirm_password"),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t("login.error_confirm_password_match")));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder={t("login.confirm_password")} />
                    </Form.Item>

                    <div className='rememberMe'>


                        <span className='sign-link'>
                            <span ><Button
                                type="text"
                                size="medium"
                                className="login-btn mt5"
                                htmlType="button"
                                disabled={isResendLoading}
                                loading={isResendLoading}
                                onClick={(e) => resendCode(e)}
                            >
                                {t("login.resend")}
                            </Button></span>
                        </span>
                    </div>

                    <Form.Item className="mt8">

                        <Row gutter={30}  className="pass-btn">
                            <Col span={14}>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    className='border'
                                    disabled={isRequestSending}
                                    loading={isRequestSending}
                                >
                                    {type === "set" ? t("login.set_password") : <span>&nbsp;{t("login.reset_password")}&nbsp;</span>}

                                </Button>

                            </Col>
                            <Col span={10}>
                                <Button style={{ width: "100%" }} type="secondary" size="large" className='border' htmlType="button" onClick={() => navigate('/login')}>
                                    {t("common.cancel")}
                                </Button>

                            </Col>
                        </Row>

                    </Form.Item>
                </Form>
            </div>

            {/* <div className='sign-footer'>
                <span className='sign-text sign-text-grey'>
                </span>
            </div> */}
        </div>
    )
}
export default ResetPasswordComponent;