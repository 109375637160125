const initialState = {
    isLoading: true,
    surveyList: [],

    isAddLoading: false,
    addStatus: "",

    isGetLoading: false,
    surveyData: {},

    isDeleteLoading: false,
    deleteStatus: "",

    isToggleLoading: false,
    streamStatus: "",

    error: "",

    isFileUploading: false,
    uploadFileURL: "",
    fileMsg: ""

};

const SurveyReducer = (state = initialState, action = {}) => {

    switch (action.type) {
        case "SURVEY_LIST_REQUEST":
            state = {
                ...state,
                isLoading: true,
                surveyList: []

            };
            break;

        case "SURVEY_LIST_SUCCESS":
            state = {
                ...state,
                isLoading: false,
                surveyList: action.data
            };
            break;

        case "SURVEY_LIST_ERROR":
            state = {
                ...state,
                isLoading: false,
                surveyList: [],
                error: action.error,
            };
            break;

        /******************************/
        case "SURVEY_ADD_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                addStatus: ''

            };
            break;

        case "SURVEY_ADD_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                addStatus: "success"
            };
            break;

        case "SURVEY_ADD_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                addStatus: ""
            };
            break;

        /******************************/
        case "SURVEY_UPDATE_REQUEST":
            state = {
                ...state,
                isAddLoading: true,
                addStatus: ''

            };
            break;

        case "SURVEY_UPDATE_SUCCESS":
            state = {
                ...state,
                isAddLoading: false,
                addStatus: "success"
            };
            break;

        case "SURVEY_UPDATE_ERROR":
            state = {
                ...state,
                isAddLoading: false,
                error: action.error,
                addStatus: ""
            };
            break;

        /******************************/
        case "SURVEY_DETAIL_REQUEST":
            state = {
                ...state,
                isGetLoading: true,
                surveyData: {}

            };
            break;

        case "SURVEY_DETAIL_SUCCESS":
            state = {
                ...state,
                isGetLoading: false,
                surveyData: action.data
            };
            break;

        case "SURVEY_DETAIL_ERROR":
            state = {
                ...state,
                isGetLoading: false,
                surveyData: {},
                error: action.error,
            };
            break;

        case "CLEAR_SURVEY_DATA":
            state = {
                ...state,
                isGetLoading: false,
                surveyData: {},
            };
            break;

        /******************************/
        case "SURVEY_DELETE_REQUEST":
            state = {
                ...state,
                isDeleteLoading: true,
                deleteStatus: ""

            };
            break;

        case "SURVEY_DELETE_SUCCESS":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteStatus: "success"
            };
            break;

        case "SURVEY_DELETE_ERROR":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteStatus: ""
            };
            break;

        /******************************/
        case "SURVEY_TOGGLE_REQUEST":
            state = {
                ...state,
                isToggleLoading: true,
                streamStatus: ""

            };
            break;

        case "SURVEY_TOGGLE_SUCCESS":
            state = {
                ...state,
                isToggleLoading: false,
                streamStatus: action.data
            };
            break;

        case "SURVEY_TOGGLE_ERROR":
            state = {
                ...state,
                isToggleLoading: false,
                streamStatus: ""
            };
            break;

        // --- Update image stepi
        case "UPLOAD_IMAGE_REQUEST":
            state = {
                ...state,
                isFileUploading: true,
                uploadFileURL: "",
                fileMsg: ""
            };
            break;

        case "UPLOAD_IMAGE_SUCCESS":
            state = {
                ...state,
                isFileUploading: false,
                uploadFileURL: action.data,
                fileMsg: "success"
            };
            break;

        case "UPLOAD_IMAGE_ERROR":
            state = {
                ...state,
                isFileUploading: false,
                uploadFileURL: "",
                fileMsg: ""
            };
            break;


        case "CLEAR_SURVEY":
            state = {
                ...state,
                addStatus: "",
                deleteStatus: "",
                surveyData: {},
                streamStatus: "",
                uploadFileURL: "",
                fileMsg: "",
                addStatus: ""
            };
            break;



        default:

    }
    return state;
};

export default SurveyReducer;