import React, { useState } from "react";
import { Upload, Button } from "antd";
import { useDispatch } from 'react-redux';

const UploadFilesandLogo = ({ label, supportType = "image", setFileObj, fileName = "" }) => {


    const handleFileChange = (info) => {

        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            setFileObj(info.file.originFileObj);
        }
    };

    const getTheFileName = (fileName) => {
        if (fileName && fileName !== "") {
            let tmpFileName = fileName.split('/').reverse();
            return tmpFileName[0];
        }
    }

    return (
        <>
            <div className="setting-imgage">
                <Upload
                    accept={supportType === "image" ? ".jpg, .jpeg, .png, .gif" : ".pdf"}
                    name="avatar"
                    className="avatar-uploader"
                    onChange={handleFileChange}
                    maxCount={1}
                    defaultFileList={fileName ? [{ name: getTheFileName(fileName) }] : []}
                    showUploadList={{ showRemoveIcon: false }}
                    customRequest={({ file, onSuccess }) =>
                        setTimeout(() => onSuccess("ok"), 0)
                    }
                >

                    <Button
                        type="secondary"
                        shape="round"
                        className="stream-btn"
                        size={"large"}>
                        {label}
                    </Button>

                </Upload>
            </div>
        </>
    );
};

export default UploadFilesandLogo;
