import { role } from "./functions";

// export const API_URL = process.env.REACT_APP_API_URL; // API base URL
export const API_URL = process.env.REACT_APP_API_URL; // API base URL
// export const API_URL = "https://uat-api-rbc.crg.ca/api/"; // API base URL
export const BASE_URL = process.env.REACT_APP_API_BASE_URL; // API base URL
export const CLIENT_NAME = process.env.REACT_APP_CLIENT || "CRG"; // Client Name
export const BASE_BACKEND_URL = process.env.REACT_APP_API_BASE_URL; // API base URL
// export const BASE_BACKEND_URL = "https://uat-api.crg.ca/api/"; // API base URL

export const TABLEAU_APP_ID = process.env.REACT_APP_TABLEAU_APP_ID;
export const TABLEAU_SECRET_ID = process.env.REACT_APP_TABLEAU_SECRET_ID;
export const TABLEAU_SECRET_VALUE= process.env.REACT_APP_TABLEAU_SECRET_VALUE;
export const TABLEAU_USER_EMAIL = process.env.REACT_APP_TABLEAU_USER_EMAIL;

export const PER_PAGE = 15; // API base URL

export const VERSION_NUMBER = "1.1.0"

export const TABLEAU_URL = process.env.REACT_APP_TABLEUA_URL || "https://prod-ca-a.online.tableau.com/t/context/views/"

export const ROLE = role();

export const SURVEY_LINK = process.env.REACT_APP_SURVEY_BASE_URL || "https://ca.decipherinc.com/survey/selfserve/";

export const ROLE_NAME = {
    "CRG_USER": "crg_user",
    "CLIENT_ADMIN": "client_admin_user",
    "CLIENT_USER": "client_user",
    "ORG_ADMIN": "org_admin_user",
    "ORG_USER": "org_user",
}
