import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Col } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QueueAnim from 'rc-queue-anim';
import AuthWrapper from '../authwrapper';

function Mfa() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const [fieldValue, setFieldValue] = useState({})

    const { isMFARequestSending, mfaMsg, isMFARequestResendLoading, mfaResendMsg } = useSelector(state => state.auth);

    const onFinish = (values) => {
        setFieldValue(values);
        dispatch({ type: 'MFA_REQUEST', formData: values});
    };

    useEffect(() => {

        if (! isMFARequestSending && mfaMsg === "success") {
             window.location.href = '/';
        }
    }, [isMFARequestSending, mfaMsg]);

    const onFinishFailed = (errorInfo) => { console.log('Failed:', errorInfo) };

    const resendCode = (e) => {
        e.preventDefault()
        dispatch({ type: 'MFA_REQUEST_RESEND', formData: { email: searchParams.get('email') !== null ? searchParams.get('email') : "" } });
    }


    return (
        <QueueAnim
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <AuthWrapper>
                <div className='sign'>
                    <div className='sign-content'>
                        <Form
                            className='mfa-form'
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            form={form}
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: false }]}
                                initialValue={searchParams.get('email') !== null ? searchParams.get('email') : ""}
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                name="pin"
                                rules={[
                                    {
                                        required: true,
                                        message: t("login.error_pin"),
                                    },
                                ]}
                            >
                                <Input placeholder={t("login.pin")} type={"number"} showCount maxLength={6} />
                            </Form.Item>

                            <div className='rememberMe'>
                                <span className='sign-link'>
                                    <span ><Button
                                        type="text"
                                        size="medium"
                                        className="login-btn mt5"
                                        htmlType="button"
                                        disabled={isMFARequestResendLoading}
                                        loading={isMFARequestResendLoading}
                                        onClick={(e) => resendCode(e)}
                                    >
                                        {t("login.resend")}
                                    </Button></span>
                                </span>
                            </div>

                            <Form.Item className="mt8">

                                <Row gutter={30}  className="pass-btn">
                                    <Col span={14}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            htmlType="submit"
                                            className='border'
                                            disabled={isMFARequestSending}
                                            loading={isMFARequestSending}
                                        >
                                            {t("common.confirm")}

                                        </Button>

                                    </Col>
                                    <Col span={10}>
                                        <Button style={{ width: "100%" }} type="secondary" size="large" className='border' htmlType="button" onClick={() => navigate('/login')}>
                                            {t("common.cancel")}
                                        </Button>

                                    </Col>
                                </Row>

                            </Form.Item>
                        </Form>
                    </div>

                </div>
            </AuthWrapper>
        </QueueAnim>
    )
}
export default Mfa;