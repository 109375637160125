import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppAnimateGroup from '../../auth/AppAnimateGroup';
import { Button } from 'antd';
import { ReactComponent as Logo } from '../../../assets/404.svg';

const Error404 = () => {
  const navigate = useNavigate();

  const onGoBackToHome = () => {
    navigate('/');
  };

  return (
    <AppAnimateGroup type='bottom'>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className='error-container' key='a'>
          <div className='error-img'>
            <Logo />
          </div>
          <div className='error-content'>
            <h2>
              404 Error
            </h2>
            <div className='error-para'>
              <p className='mb-0'>
                We can't find the page that
              </p>
              <p className='mb-0'>
                you are looking for.
              </p>
            </div>
            <Button type='primary' className='error-btn' onClick={onGoBackToHome}>
              Back To Home
            </Button>
          </div>
        </div>
      </div>
    </AppAnimateGroup>
  );
};

export default Error404;
