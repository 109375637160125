import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'
import { ConfigProvider, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import frFR from 'antd/es/locale/fr_FR';
//Public routes without login access
import Login from './components/auth/login';
import ForgetPassword from './components/auth/password/forgetPassword';
import ResetPassword from './components/auth/password/resetPassword';
import Mfa from './components/auth/login/Mfa';
import SessionExpiryModal from "./components/pages/user/sessionExpiryModal";
//Layouts
import Header from './components/pages/layout/header';
import Sidebar from './components/pages/layout/sidebar';
import Breadcrumb from './components/pages/layout/breadcrumb';

//Components
import Dashboard from './components/pages/dashboard';
import Users from './components/pages/user';
import UserAdd from "./components/pages/user/addUser";
import Reports from './components/pages/reports';
import ViewReport from './components/pages/reports/viewReport';
import ReportsManagement from './components/pages/reportManagement';
import ReportAdd from "./components/pages/reportManagement/addReport";
import ReportGroupAdd from "./components/pages/reportManagement/addGroupReport";
import Organization from './components/pages/organization';
import OrganizationAdd from './components/pages/organization/addOrganization';
import Streams from './components/pages/streams';
import StreamAdd from './components/pages/streams/addStream';
import SurveyAdd from "./components/pages/surveys/addSurveys";
import Settings from "./components/pages/settings";
import Surveys from "./components/pages/surveys";
import NotFound from "./components/pages/Error/404";
import Forbidden from "./components/pages/Error/403";
import Version from "./components/pages/comnon/version";

import { isLogin, isAuthorized, roleList } from "./utils/functions";
import "./App.scss";


const { Content } = Layout;


function App() {

  const {t, i18n} = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onIdle = () => {
    if (isLogin()) {
      setIsModalVisible(true);
    }
    console.log('User is idle');
  }

  const onAction = (event) => {
    console.log(event.type);
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onAction,
    timeout: 3_300000,
    throttle: 1000,
    events: [
      'mousedown',
      'keydown',
    ]
  });

  const handleContinue = () => {
    setIsModalVisible(false);
  };

  const ProtectedRoute = (props) => {
    if (!isLogin()) {
      return <Navigate to="/login" replace />;
    } else {
      if (isAuthorized(props.allowed)) {
        return (
          <Layout className="main-layout">
            <Header />
            <Layout>
              <Sidebar />
              <Layout
                style={{
                  padding: '0 24px 24px',
                }}
                className="content-layout"
              >
                <Breadcrumb />
                <Content
                  className="site-layout-background content"
                  style={{
                    padding: "25px 50px",
                    margin: 0,
                    minHeight: 280,
                  }}
                >
                  {props.children}
                </Content>
              </Layout>
            </Layout>
            <Version />
          </Layout>
        )
      } else {
        return (
          <Layout>
            <Header />
            <Layout>
              <Sidebar />
              <Layout
                style={{
                  padding: '0 24px 24px',
                }}
              >
                <Breadcrumb />
                <Content
                  className="site-layout-background content"
                  style={{
                    padding: "25px 50px",
                    margin: 0,
                    minHeight: 280,
                  }}
                >
                  <Forbidden />
                </Content>
              </Layout>
            </Layout>
          </Layout>
        )
      }

    }

  };

  const PublicRoutes = ({ children }) => {
    if (!isLogin()) {
      return <Layout>{children}</Layout>;
    } else {
      return <Navigate to="/" replace />
    }
  };

  // type 0 = Public
  // type 1 = Private
  const routesList = [
    {
      path: "/login",
      type: 0,
      element: <Login />,
      role: [],
    },
    {
      path: "/mfa",
      type: 0,
      element: <Mfa />,
      role: [],
    },
    {
      path: "/forget-password",
      type: 0,
      element: <ForgetPassword />,
      role: [],
    },
    {
      path: "/set-password",
      type: 0,
      element: <ResetPassword type="set" />,
      role: [],
    },
    {
      path: "/reset-password",
      type: 0,
      element: <ResetPassword type="reset" />,
      role: [],
    },
    {
      path: "/",
      type: 1,
      element: <Dashboard />,
      role: roleList('home'),
    },
    {
      path: "/home",
      type: 1,
      element: <Dashboard />,
      role: roleList('home'),
    },
    {
      path: "/user",
      type: 1,
      element: <Users />,
      role: roleList('user'),
    },
    {
      path: "/user/add",
      type: 1,
      element: <UserAdd />,
      role: roleList('user'),
    },
    {
      path: "/user/edit/:userId",
      type: 1,
      element: <UserAdd />,
      role: roleList('user'),
    },
    {
      path: "/organization",
      type: 1,
      element: <Organization />,
      role: roleList('organization'),
    },
    {
      path: "/organization/add",
      type: 1,
      element: <OrganizationAdd />,
      role: roleList('organization'),
    },
    {
      path: "/organization/edit/:orgId",
      type: 1,
      element: <OrganizationAdd />,
      role: roleList('organization'),
    },
    {
      path: "/reports",
      type: 1,
      element: <Reports />,
      role: roleList('reports'),
    },
    {
      path: "/view-report",
      type: 1,
      element: <ViewReport />,
      role: roleList('reports'),
    },
    {
      path: "/reports",
      type: 1,
      element: <Reports />,
      role: roleList('reports'),
    },
    {
      path: "/reports-management",
      type: 1,
      element: <ReportsManagement />,
      role: roleList('reports-management'),
    },
    {
      path: "/reports-management/add",
      type: 1,
      element: <ReportAdd />,
      role: roleList('reports-management'),
    },
    {
      path: "/reports-management/add-group",
      type: 1,
      element: <ReportGroupAdd />,
      role: roleList('reports-management'),
    },
    {
      path: "/reports-management/edit/:reportId",
      type: 1,
      element: <ReportAdd />,
      role: roleList('reports-management'),
    },
    {
      path: "/reports-management/edit-group/:reportId",
      type: 1,
      element: <ReportGroupAdd />,
      role: roleList('reports-management'),
    },
    {
      path: "/streams",
      type: 1,
      element: <Streams />,
      role: roleList('streams'),
    },
    {
      path: "/stream/add",
      type: 1,
      element: <StreamAdd />,
      role: roleList('streams'),
    },
    {
      path: "/stream/edit/:streamId",
      type: 1,
      element: <StreamAdd />,
      role: roleList('streams'),
    },
    {
      path: "/surveys",
      type: 1,
      element: <Surveys />,
      role: roleList('surveys'),
    },
    {
      path: "/survey/add",
      type: 1,
      element: <SurveyAdd />,
      role: roleList('surveys'),
    },
    {
      path: "/survey/edit/:surveyId",
      type: 1,
      element: <SurveyAdd />,
      role: roleList('surveys'),
    },
    {
      path: "/settings",
      type: 1,
      element: <Settings />,
      role: roleList('settings'),
    },
    {
      path: "*",
      type: 1,
      element: <NotFound />,
      role: [],
    },
  ]

  return (
      <ConfigProvider locale={i18n.language === 'fr' ? frFR : ''}>
        <BrowserRouter>
      <Routes>
        {routesList.map((item) => {
          if (item.type === 0) {
            return (
              <Route
                path={item.path}
                element={
                  <PublicRoutes>
                    {item.element}
                  </PublicRoutes>
                }>
              </Route>
            )
          } else {
            return (
              <Route
                path={item.path}
                element={
                  <ProtectedRoute allowed={item.role}>
                    {item.element}
                  </ProtectedRoute>
                }>
              </Route>
            )
          }
        })}

      </Routes >
      <SessionExpiryModal visible={isModalVisible}
                          onContinue={handleContinue}
      />
    </BrowserRouter >
      </ConfigProvider>
  );
}
export default (App);
