/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

function ForgetPasswordComponent() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { isForgetPasswordLoading, forgetMsg } = useSelector(state => state.auth);
    const [fieldValue, setFieldValue] = useState({})


    const onFinish = (values) => {
        setFieldValue(values)
        dispatch({ type: 'FORGET_PASSWORD_REQUEST', formData: values });
    };

    useEffect(() => {
        if (!isForgetPasswordLoading && forgetMsg === "success") {
            message.success(t("login.mail_sent"));
            dispatch({ type: "CLEAR_INVITE" });
            navigate('/reset-password?email=' + fieldValue.email)
        } else if (!isForgetPasswordLoading && forgetMsg?.errors) {
            let msg = forgetMsg?.message;
            msg = msg.replace('selected', 'given');
            message.error({ content: msg || "Email address not exists", className: 'error-class', duration: 3 });
        } else if (!isForgetPasswordLoading && forgetMsg?.message) {
            message.error({ content: forgetMsg?.message, className: 'error-class', duration: 3 });
        }
        dispatch({ type: "CLEAR_INVITE" });
    }, [isForgetPasswordLoading, forgetMsg])

    const onFinishFailed = (errorInfo) => { console.log('Failed:', errorInfo) };


    return (
        <div className='sign'>
            <div className='sign-content'>
                <Form
                    className='sign-form'
                    name='basic'
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>
                    <Form.Item
                        name='email'
                        className='form-field'
                        rules={
                            [
                                {
                                    type: 'email',
                                    message: t("login.error_valid_email"),
                                },
                                {
                                    required: true,
                                    message: t("login.error_email")
                                }]
                            // [{ required: true, type: "email", message: 'Please input your Email!' }]
                        }
                    >
                        <Input placeholder={t("login.email")} />
                    </Form.Item>

                    <div className='form-btn-field'>
                        <Button
                            type='primary'
                            htmlType='submit'
                            className='sign-btn'
                            loading={isForgetPasswordLoading}
                            disabled={isForgetPasswordLoading}
                        >
                            {t("login.reset_password_cap")}
                        </Button>
                    </div>

                    <div className='form-field-action'>
                        <span className='sign-text-grey'>
                            {t("login.do_you_have")}
                        </span>
                        <Link to='/login' className='underlineNone colorTextPrimary'>
                            {t("login.sign_in")}
                        </Link>
                    </div>
                </Form>
            </div>

            {/* <div className='sign-footer'>
                <span className='sign-text sign-text-grey'>
                </span>
            </div> */}
        </div>
    )
}
export default ForgetPasswordComponent;