import { put, takeLatest, call, delay } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { deleteRequestAPI, getRequestAPI, postRequestAPI, putRequestAPI, patchRequestAPI } from '../../services/API/api';

function* getStreamList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `streams`, params: formData });

        if (response.status === 200) {
            yield put({ type: 'STREAM_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'STREAM_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'STREAM_LIST_ERROR', error: error.message || "" });
    }
}

function* addStream({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `stream`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'STREAM_ADD_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'STREAM_ADD_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'STREAM_ADD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* updateStream({ id, formData }) {
    try {
        const response = yield call(putRequestAPI, { url: `stream/${id}`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'STREAM_UPDATE_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'STREAM_UPDATE_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'STREAM_UPDATE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* getStreamDetail({ id }) {
    try {
        const response = yield call(getRequestAPI, { url: `stream/${id}`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'STREAM_DETAIL_SUCCESS', data: response?.data?.data || [] });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });

            yield put({ type: 'STREAM_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'STREAM_DETAIL_ERROR', error: error.message || "" });
    }
}
function* deleteStream({ id }) {
    try {
        const response = yield call(deleteRequestAPI, { url: `stream/${id}` });
        if (response.status === 200 || response.status === 204) {
            yield put({ type: 'STREAM_DELETE_SUCCESS' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'STREAM_DELETE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'STREAM_DELETE_ERROR', error: error.message || "" });
    }
}
function* updateStatus({ streamId, status }) {
    try {
        const response = yield call(putRequestAPI, { url: `stream/${streamId}/toggle-status` });
        if (response.status === 200) {
            yield put({ type: 'STREAM_TOGGLE_SUCCESS', data: response.data.data.status ? 'activated' : 'archived' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'STREAM_TOGGLE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'STREAM_TOGGLE_ERROR', error: error.message || "" });
    }
}

function* updateSurveyStatus({ surveyId, status }) {
    try {
        const response = yield call(putRequestAPI, { url: `stream-survey/${surveyId}/toggle-status` });
        if (response.status === 200) {
            yield put({ type: 'STREAM_SURVEY_TOGGLE_SUCCESS', data: response.data.data.status ? 'activated' : 'archived' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'STREAM_SURVEY_TOGGLE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'STREAM_SURVEY_TOGGLE_ERROR', error: error.message || "" });
    }
}


function* uploadDocument({ formData }) {
    try {

        const response = yield call(postRequestAPI, { url: `upload-document`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'UPLOAD_IMAGE_SUCCESS', data: response.data?.data?.url || "" });
        } else {
            yield put({ type: 'UPLOAD_IMAGE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'UPLOAD_IMAGE_ERROR', error: error.message || "" });
    }
}

function* watchDonorConfigSagasRequests() {

    yield takeLatest('STREAM_LIST_REQUEST', getStreamList);
    yield takeLatest('STREAM_ADD_REQUEST', addStream);
    yield takeLatest('STREAM_UPDATE_REQUEST', updateStream);
    yield takeLatest('STREAM_DETAIL_REQUEST', getStreamDetail);
    yield takeLatest('STREAM_DELETE_REQUEST', deleteStream);
    yield takeLatest('STREAM_TOGGLE_REQUEST', updateStatus);
    yield takeLatest('STREAM_SURVEY_TOGGLE_REQUEST', updateSurveyStatus);

    yield takeLatest('UPLOAD_DOCUMENT_REQUEST', uploadDocument);



}

function* donorConfigSagas() {
    yield watchDonorConfigSagasRequests();
}

export default donorConfigSagas;