import { DeploymentUnitOutlined, HomeOutlined, UsergroupAddOutlined, BarChartOutlined, SettingOutlined, SnippetsOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import { isMenuVisible } from "../../../utils/functions";
import { useTranslation } from "react-i18next";
import { TiWaves } from 'react-icons/ti';
import { useSelector, useDispatch } from 'react-redux';
const { Sider } = Layout;


const Sidebar = () => {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const [active, setActive] = useState('home')
    const [collapsed, setCollapsed] = useState(false)
    const [items1, setItem1] = useState([]);
    const { isSettingLoading, settingsData } = useSelector(state => state.user);

    useEffect(() => {
        dispatch({ type: 'SETTINGS_REQUEST', formData: {} });
    }, []);



    useEffect(() => {
        if (!isSettingLoading && Object.keys(settingsData).length > 0) {
            setItem1([
                {
                    key: "home",
                    label: t("sidebar.home"),
                    icon: <HomeOutlined />,
                    style: { display: isMenuVisible('home') },
                },
                {
                    key: "user",
                    label: t("sidebar.users"),
                    icon: <UsergroupAddOutlined />,
                    style: { display: isMenuVisible('user') },
                },
                {
                    key: "reports",
                    label: t("sidebar.reports"),
                    icon: <BarChartOutlined />,
                    style: { display: isMenuVisible('reports') },
                },
                {
                    key: "reports-management",
                    label: t("sidebar.reports_management"),
                    icon: <SettingOutlined />,
                    style: { display: isMenuVisible('reports-management') },
                },
                {
                    key: "organization",
                    label: t("sidebar.organizations"),
                    icon: <DeploymentUnitOutlined />,
                    style: { display: isMenuVisible('organization') },
                },
                {
                    key: "surveys",
                    label: t("sidebar.surveys"),
                    icon: <SnippetsOutlined />,
                    style: { display: isMenuVisible('surveys', settingsData?.enable_stream_survey || 0) },
                },
                {
                    key: "streams",
                    label: t("sidebar.streams"),
                    icon: <TiWaves fontSize={15} />,
                    style: { display: isMenuVisible('streams', settingsData?.enable_stream_survey || 0) },
                },
                {
                    key: "settings",
                    label: t("sidebar.settings"),
                    icon: <SettingOutlined />,
                    style: { display: isMenuVisible('settings') },
                },
            ]);
        }
    }, [t, i18n.language, isSettingLoading, settingsData]);

    useEffect(() => {
        if (location.pathname.includes('home')) {
            setActive('home');
        } else if (location.pathname.includes('user')) {
            setActive('user');
        } else if (location.pathname.includes('reports-management')) {
            setActive('reports-management');
        } else if (location.pathname.includes('reports')) {
            setActive('reports');
        } else if (location.pathname.includes('organization')) {
            setActive('organization');
        } else if (location.pathname.includes('stream')) {
            setActive('streams');
        } else if (location.pathname.includes('survey')) {
            setActive('surveys');
        }
    }, [location]);

    return (
        <Sider width={230}
            breakpoint="sm"
            onBreakpoint={(broken) => {
            }}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            className="site-layout-background">
            <Menu
                mode="inline"
                selectedKeys={[active]}
                onClick={(e) => {
                    setActive(e.key);
                    navigate(`/${e.key}`);
                }}
                defaultOpenKeys={['sub1']}
                items={items1}
                style={{
                    height: '100%',
                    borderRight: 0,
                }}
            >
            </Menu>
        </Sider>
    );
};

export default Sidebar;