import React, { useState } from "react";
import { Upload, Button } from "antd";
import { useDispatch } from 'react-redux';

const FileUpload = ({ label, fileName = "", callFunction, fieldName }) => {

    const dispatch = useDispatch();

    const handleFileChange = (info) => {

        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            callFunction(fieldName, 'add')
            const formData = new FormData();
            formData.append("document", info.file.originFileObj);
            dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST', formData: formData });
        }
    };


    return (
        <>
            <div className="project-building-image-parent">
                <Upload
                    accept=".pdf"
                    name="avatar"
                    className="avatar-uploader"
                    onChange={handleFileChange}
                    maxCount={1}
                    defaultFileList={fileName ? [{ name: fileName }] : []}
                    onRemove={() => callFunction(fieldName, 'remove')}
                    customRequest={({ file, onSuccess }) =>
                        setTimeout(() => onSuccess("ok"), 0)
                    }
                >

                    <Button
                        type="primary"
                        shape="round"
                        className="stream-btn"
                        size={"large"}>
                        {label}
                    </Button>

                </Upload>
            </div>
        </>
    );
};

export default FileUpload;
